import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import camelcaseKeys from 'camelcase-keys'
import { emptyAccount } from '@/models'
import { deepOmit } from '@/utils'
import { Account } from '@@/types'

// TODO: make deepOmit act as middleware on the client

const UserFragment = gql`
  fragment UserFragment on user {
    id
    first_name
    last_name
    email
    image_url
    groups {
      group {
        id
        organization {
          id
          invoice_workflow_id
          invoice_workflow {
            id
            stages {
              id
              name
              meta
              created_at
            }
          }
          json_settings {
            setting
          }
        }
      }
    }
    json_settings {
      setting
    }
    email_config {
      send_via
      inbox_linked
    }
    # plan
    # settings {
    #   detailPresets {
    #     id
    #     name
    #     category
    #     content
    #   }
    # }
  }
`

export function empty(override: Partial<Account> = {}): Account {
  return {
    id: uuid(),
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: null,
    quickbooksLinked: false,
    settings: {
      detailPresets: [],
    },
    ...override,
  }
}

const useAll =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<Account[]> => {
    const { data } = await client.query({
      query: gql`
        query {
          accounts {
            ...UserFragment
          }
        }

        ${UserFragment}
      `,
    })

    return data.accounts
  }

const useGet =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<Account> => {
    const { data } = await client.query({
      query: gql`
        query GetAccount {
          user {
            ...UserFragment
          }

          core {
            verifyQuickbooksAccountLink
          }
        }

        ${UserFragment}
      `,
    })

    return camelcaseKeys(
      emptyAccount({
        ...data.user.at(0),
        quickbooksLinked: data.core.verifyQuickbooksAccountLink,
      }),
      { deep: true }
    )
  }

const useCreate =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (account: Account): Promise<Account> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, ...input } = account

    const { data } = await client.mutate({
      mutation: gql`
        mutation CreateAccount($input: AccountInput!) {
          accountCreate(input: $input) {
            ...UserFragment
          }
        }

        ${UserFragment}
      `,
      variables: {
        input: deepOmit(input, '__typename'),
      },
    })

    return data.accountCreate
  }

const useUpdate =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (id: string, account: Account) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _id, ...input } = account

    const { data } = await client.mutate({
      mutation: gql`
        mutation UpdateAccount($id: ID!, $input: AccountInput!) {
          accountUpdate(id: $id, input: $input) {
            ...UserFragment
          }
        }

        ${UserFragment}
      `,
      variables: {
        id,
        input: deepOmit(input, '__typename'),
      },
    })

    return data.accountUpdate
  }

const useRemove =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (id: string): Promise<boolean> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation RemoveAccount($id: ID!) {
          accountRemove(id: $id)
        }
      `,
      variables: {
        id,
      },
    })

    return data.accountRemove
  }

export const useAccountModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    all: useAll(client),
    get: useGet(client),
    create: useCreate(client),
    update: useUpdate(client),
    remove: useRemove(client),
  }
}
