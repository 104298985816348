<script setup lang="ts">
import { ref, watch } from 'vue'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import { Btn } from '@/components'
import { Size, Variant, VueClassAttr } from '@@/types'

const props = withDefaults(
  defineProps<{
    show: boolean
    title?: string
    titleClass?: VueClassAttr
    confirmText?: string
    cancelText?: string
    confirmDisabled?: boolean
    confirmVariant?: Variant
    footerClass?: VueClassAttr
    size?: Size
    noAutoClose?: boolean
  }>(),
  {
    title: 'Confirmation',
    titleClass: '',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    confirmVariant: 'primary',
    footerClass: 'flex items-center justify-end gap-2',
    size: 'sm',
  }
)

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'cancel'): void
  (e: 'close'): void
}>()

const confirmButton = ref(null)
const isOpen = ref(false)

const setIsOpen = (value: boolean) => {
  isOpen.value = value
}

watch(
  () => props.show,
  (value) => (isOpen.value = !!value)
)

const close = () => {
  emit('close')
  setIsOpen(false)
}

const confirm = () => {
  emit('confirm')
  if (!props.noAutoClose) close()
}

const cancel = () => {
  emit('cancel')
  close()
}
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      :open="isOpen"
      class="fixed inset-0 z-30 overflow-y-auto"
      @close="close"
    >
      <TransitionChild
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-black/50" />
      </TransitionChild>

      <TransitionChild
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-90"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-90"
      >
        <div
          class="relative mx-auto my-5 rounded bg-white p-5 shadow-lg"
          :class="{
            'max-w-sm': size === 'sm',
            'max-w-lg': size === 'md',
            'max-w-2xl': size === 'lg',
          }"
        >
          <header class="mb-3">
            <DialogTitle :class="titleClass">
              {{ title }}
            </DialogTitle>
          </header>

          <div class="mb-5">
            <slot />
          </div>

          <footer :class="footerClass">
            <Btn
              ref="confirmButton"
              :variant="confirmVariant"
              :disabled="confirmDisabled"
              class="flex items-center"
              @click="confirm"
            >
              <slot name="confirm-text">
                {{ confirmText }}
              </slot>
            </Btn>

            <Btn outline class="flex items-center" @click="cancel">
              <slot name="cancel-text">
                {{ cancelText }}
              </slot>
            </Btn>
          </footer>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
