import { LineItem } from '@@/types'

export default (
  lineItems: LineItem[],
  workflowId?: string,
  workflowStageId?: string
) => {
  // Portraits workflow
  const usingPortraitsWorkflow =
    workflowId === 'e23b374f-7838-4817-a531-b27d87bc2b10'

  const onTravelInvoiceStage =
    workflowStageId === 'bcf1746b-6031-4c0b-80a2-4da2521cb81f'

  const onFinalInvoiceStage =
    workflowStageId === 'fa5af515-c059-48a5-a93f-f4388529eced'

  const transactionFeeItem =
    lineItems?.find((item) => item.specialCase === 'credit card') || null

  const filteredLineItems = usingPortraitsWorkflow
    ? onTravelInvoiceStage
      ? lineItems?.filter(
          (item) => item.specialCase === 'travel' || item.inventory
        )
      : onFinalInvoiceStage
      ? lineItems?.filter((item) => item.specialCase !== 'credit card')
      : lineItems?.filter(
          (item) =>
            item.specialCase !== 'travel' && item.specialCase !== 'credit card'
        )
    : lineItems?.filter((item) => item.specialCase !== 'credit card')

  let pricedLineItems =
    usingPortraitsWorkflow && onTravelInvoiceStage
      ? filteredLineItems.filter((item) => !item.inventory)
      : filteredLineItems

  if (transactionFeeItem)
    pricedLineItems = pricedLineItems.concat(transactionFeeItem)

  return { filteredLineItems, pricedLineItems, transactionFeeItem }
}
