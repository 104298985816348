import gql from 'graphql-tag'
import { ArtistFragment } from '../artist'
import { InventoryMiscFragment } from './inventoryMisc'
import { InventoryXContactFragment } from './inventoryXContact'

export const InventoryFragment = gql`
  fragment InventoryFragment on InventoryAll {
    id
    artist
    artists: artistObj {
      ...ArtistFragment
    }
    title
    title_2
    year
    description
    catalog_number
    edition
    consignor
    size_text
    size_text_metric
    framed_size
    framed_size_metric
    location
    medium
    price
    price_currency
    vat_status
    additional_notes
    status
    invoice_status
    url
    resource_url
    inventory_misc: primary_inventory_misc {
      ...InventoryMiscFragment
    }
    artwork_custom_fields {
      id
      organization_custom_field {
        id
        position
        attributes
        created_at
        updated_at
        archived_at
        deleted_at
      }
      field_id
      value
      attributes
      created_at
      updated_at
    }
    inventory_x_contacts: inventoryContact {
      ...InventoryXContactFragment
    }
    __typename
  }
  ${ArtistFragment}
  ${InventoryMiscFragment}
  ${InventoryXContactFragment}
`