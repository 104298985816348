import handlebars from 'handlebars'
import {
  formatAddress,
  formatContact,
  formatContactDetails,
  formatCurrency,
  formatPercent,
  formatDate,
  formatDateShort,
  formatTaxDetails,
  cleanJoin,
  thumbnailUrl,
} from '@/utils'

handlebars.registerHelper('formatAddress', formatAddress)
handlebars.registerHelper('formatContact', formatContact)
handlebars.registerHelper('formatContactDetails', formatContactDetails)
handlebars.registerHelper('formatCurrency', formatCurrency)
handlebars.registerHelper('formatPercent', formatPercent)
handlebars.registerHelper('formatPercent', formatPercent)
handlebars.registerHelper('formatTaxDetails', formatTaxDetails)
handlebars.registerHelper('formatDate', formatDate)
handlebars.registerHelper('formatDateShort', formatDateShort)
handlebars.registerHelper('cleanJoin', cleanJoin)
handlebars.registerHelper('thumbnailUrl', (src: string, width: number, height: number) => {
  return thumbnailUrl(src, import.meta.env.VITE_THUMBNAIL_BASE_URL, width, height)
})

handlebars.registerHelper('formatDateNumeric', (date: Date, locale = 'en-US') =>
  new Date(date).toLocaleDateString(locale)
)

handlebars.registerHelper(
  'and',
  (expressionA, expressionB) => expressionA && expressionB
)
handlebars.registerHelper(
  'or',
  (expressionA, expressionB) => expressionA || expressionB
)
handlebars.registerHelper('join', (delimiter: string, stringA, stringB) =>
  [stringA, stringB].join(delimiter)
)
handlebars.registerHelper('ifeq', (a, b, options) =>
  a == b ? options.fn(this) : options.inverse(this)
)
handlebars.registerHelper('ifneq', (a, b, options) =>
  a != b ? options.fn(this) : options.inverse(this)
)
handlebars.registerHelper('gt', (a, b) => a > b)

handlebars.registerHelper('assign', function (varName, varValue, options) {
  if (!options.data.root) {
      options.data.root = {};
  }
  options.data.root[varName] = varValue;
});

export default handlebars
