import { inject, Plugin } from 'vue'
import * as FullStory from '@fullstory/browser'
import auth from '@/auth'
import { client } from '@/plugins/model'
import { useAccountModel } from '@/models'
import { cleanJoin } from '@/utils'

export function getFullStory() {
  return FullStory
}

export const key = Symbol('fullstory')

export function createFullStory(options: FullStory.SnippetOptions): Plugin {
  return {
    install: async (app) => {
      FullStory.init(options)

      app.provide(key, FullStory)

      await auth.checkSession()

      if (auth.isAuthenticated.value) {
        const accountModel = useAccountModel(client)
        const account = await accountModel.get()

        FullStory.identify(account.id, {
          displayName: cleanJoin([account.firstName, account.lastName]),
          email: account.email,
        })
      }
    },
  }
}

export function useFullStory() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<typeof FullStory>(key)!
}
