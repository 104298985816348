import { inject, Plugin } from 'vue'
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import auth from '@/auth'
import { useStore } from '@/store'

export const clientKey = Symbol('modelClient')

export let client: ApolloClient<NormalizedCacheObject>

export function createModel(): Plugin {
  return {
    install: (app) => {
      const store = useStore()
      const authMiddleware = setContext(async () => {
        try {
          const accessToken = await auth.getAccessTokenSilently()
          // throw new Error('test')
          store.auth0TokenExpired = false
          return {
            headers: { authorization: `Bearer ${accessToken}` },
          }
        } catch (e:any) {
          console.log(`error`, e?.message)
          store.auth0TokenExpired = true
        }
      })

      const arternalHttpLink = new HttpLink({
        uri: import.meta.env.VITE_API_URL,
      })

      client = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([
          authMiddleware,
          // hasuraAdminMiddleware,
          arternalHttpLink,
        ]),
        // disable cache
        defaultOptions: {
          watchQuery: { fetchPolicy: 'no-cache' },
          query: { fetchPolicy: 'no-cache' },
        },
      })

      app.provide(clientKey, client)
    },
  }
}

export function useModelClient() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<ApolloClient<NormalizedCacheObject>>(clientKey)!
}
