import gql from 'graphql-tag'

export default gql`
query getTags($type: String) {
  tagsList(type: $type) {
    id
    tag
    source
  }
}
`

