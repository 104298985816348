import { createApp, provide, h } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import auth from './auth'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import { createMixpanel } from '@/plugins/mixpanel'
import { createModel } from '@/plugins/model'
import { apolloClient as crmApolloClient } from '@/plugins/crmGraphql'
import { createScrollbars } from '@/plugins/scrollbars'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import 'vue-multiselect/dist/vue-multiselect.css';
import '@/assets/index.css'
import { createFullStory } from '@/plugins/fullstory'
import { createIntercom } from '@/plugins/intercom'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { DefaultApolloClient } from '@vue/apollo-composable'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, crmApolloClient)
  },

  render: () => h(App)
})

app.use(router)
app.use(createHead())
app.use(createPinia())
app.use(i18n)
app.use(auth)
app.use(createModel())
app.use(
  createMixpanel({
    token: import.meta.env.VITE_MIXPANEL_TOKEN,
    config: {
      debug: process.env.NODE_ENV === 'development',
      // disable in development and staging
      disable: process.env.NODE_ENV !== 'production',
    },
  })
)
// https://github.com/fullstorydev/fullstory-browser-sdk
app.use(
  createFullStory({
    orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
    debug: process.env.NODE_ENV === 'development',
    // if true will only send one `devMode` event
    // enable in development and staging to prevent spamming fullstory
    devMode: process.env.NODE_ENV !== 'production',
  })
)
app.use(Toast, {
  position: POSITION.TOP_CENTER,
  timeout: 3000,
  hideProgressBar: true,
} as PluginOptions)
app.use(createScrollbars())
// https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
app.use(
  createIntercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    vertical_padding: 20,
  })
)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'arternal.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    logErrors: true,
  })
}
app.mount('#app')
