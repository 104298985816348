import { cleanJoin } from '@/utils'
import { Contact, Address } from '@@/types'

export default (
  contact?: Contact,
  address?: Address,
  pairZipWithCountry?: boolean
) => {
  const lines = []
  if (contact?.firstName || contact?.lastName) {
    lines.push(
      cleanJoin([
        contact.honorific ?? '',
        cleanJoin([
          contact.firstName,
          contact.middleName,
          contact.lastName,
          contact.pronouns ? `(${contact.pronouns})` : '',
        ]),
      ])
    )
  }
  if (contact?.attn) {
    lines.push(contact.attn)
  }

  if (address?.line1) {
    lines.push(address.line1)
  }
  if (address?.line2) {
    lines.push(address.line2)
  }

  if (pairZipWithCountry === true && (address?.locality || address?.region)) {
    lines.push(cleanJoin([address.locality, address.region], ', '))
  } else if (address?.locality || address?.region || address?.postalCode) {
    lines.push(
      cleanJoin(
        [address.locality, cleanJoin([address.region, address.postalCode])],
        ', '
      )
    )
  }

  if (
    pairZipWithCountry === true &&
    (address?.postalCode || address?.country)
  ) {
    lines.push(cleanJoin([address.country, address.postalCode], ' '))
  } else if (address?.country) {
    lines.push(address.country)
  }

  if (contact?.phoneHome) {
    lines.push(contact.phoneHome)
  }
  if (contact?.email) {
    lines.push(contact.email)
  }
  return lines
}
