import { inject, Plugin } from 'vue'
import mixpanel, { Config } from 'mixpanel-browser'
import { useAccountModel } from '@/models'
import auth from '@/auth'
import { client } from '@/plugins/model'

export function getMixpanel() {
  return mixpanel
}

export const key = Symbol('mixpanel')

export function createMixpanel(options: {
  token: string
  config?: Partial<Config & { disable: boolean }>
}): Plugin {
  if (!options.token) {
    throw new Error('Mixpanel token not set')
  }

  const { disable, ...config } = options.config ?? {}

  return {
    install: async (app) => {
      mixpanel.init(options.token, config)

      app.provide(key, mixpanel)

      if (disable) {
        mixpanel.disable()
      }

      await auth.checkSession()

      if (auth.isAuthenticated.value) {
        const accountModel = useAccountModel(client)
        const account = await accountModel.get()

        mixpanel.people.set({
          $distinct_id: account.id,
          $first_name: account.firstName,
          $last_name: account.lastName,
          $email: account.email,
        })
        mixpanel.identify(account.id)
      }

    },
  }
}

export function useMixpanel() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<typeof mixpanel>(key)!
}
