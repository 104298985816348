// based on https://github.com/jonschlinkert/is-plain-object/blob/master/is-plain-object.js
function isObject(input: unknown) {
  return Object.prototype.toString.call(input) === '[object Object]'
}

export default (input: unknown) => {
  if (isObject(input) === false) return false

  const ctor = (input as object).constructor
  if (ctor === undefined) return true

  const prot = ctor.prototype
  if (isObject(prot) === false) return false
  // eslint-disable-next-line no-prototype-builtins
  if (prot.hasOwnProperty('isPrototypeOf') === false) return false

  return true
}
