import { onBeforeUnmount, ref, watch } from 'vue'
import { VueRef } from '@@/types'

export function useDimension(
  dimension: Exclude<keyof DOMRectReadOnly, 'toJSON'> = 'height'
) {
  const element = ref()
  const dim = ref<number>(0)

  let debouncer: ReturnType<typeof requestAnimationFrame> | null = null
  const updateDebounce = (value: number) => {
    if (debouncer) cancelAnimationFrame(debouncer)

    debouncer = requestAnimationFrame(() => {
      debouncer = null
      dim.value = value
    })
  }

  const observer = new ResizeObserver((entries) =>
    updateDebounce(entries[0].contentRect[dimension])
  )

  watch(
    () => element.value,
    (value) => {
      if (value) {
        observer.disconnect()
        observer.observe(value)
      }
    }
  )

  onBeforeUnmount(() => {
    observer.disconnect()
  })

  const functionRef = (el: VueRef) => {
    element.value = el
  }

  return { ref: functionRef, dimension: dim }
}
