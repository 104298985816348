const states = [
  { min: 35000, max: 36999, code: 'AL', name: 'Alabama' },
  { min: 99500, max: 99999, code: 'AK', name: 'Alaska' },
  { min: 85000, max: 86999, code: 'AZ', name: 'Arizona' },
  { min: 71600, max: 72999, code: 'AR', name: 'Arkansas' },
  { min: 90000, max: 96699, code: 'CA', name: 'California' },
  { min: 80000, max: 81999, code: 'CO', name: 'Colorado' },
  { min: 6000, max: 6999, code: 'CT', name: 'Connecticut' },
  { min: 19700, max: 19999, code: 'DE', name: 'Deleware' },
  { min: 32000, max: 34999, code: 'FL', name: 'Florida' },
  { min: 30000, max: 31999, code: 'GA', name: 'Georgia' },
  { min: 96700, max: 96999, code: 'HI', name: 'Hawaii' },
  { min: 83200, max: 83999, code: 'ID', name: 'Idaho' },
  { min: 60000, max: 62999, code: 'IL', name: 'Illinois' },
  { min: 46000, max: 47999, code: 'IN', name: 'Indiana' },
  { min: 50000, max: 52999, code: 'IA', name: 'Iowa' },
  { min: 66000, max: 67999, code: 'KS', name: 'Kansas' },
  { min: 40000, max: 42999, code: 'KY', name: 'Kentucky' },
  { min: 70000, max: 71599, code: 'LA', name: 'Louisiana' },
  { min: 3900, max: 4999, code: 'ME', name: 'Maine' },
  { min: 20600, max: 21999, code: 'MD', name: 'Maryland' },
  { min: 1000, max: 2799, code: 'MA', name: 'Massachusetts' },
  { min: 48000, max: 49999, code: 'MI', name: 'Michigan' },
  { min: 55000, max: 56999, code: 'MN', name: 'Minnesota' },
  { min: 38600, max: 39999, code: 'MS', name: 'Mississippi' },
  { min: 63000, max: 65999, code: 'MO', name: 'Missouri' },
  { min: 59000, max: 59999, code: 'MT', name: 'Montana' },
  { min: 27000, max: 28999, code: 'NC', name: 'North Carolina' },
  { min: 58000, max: 58999, code: 'ND', name: 'North Dakota' },
  { min: 68000, max: 69999, code: 'NE', name: 'Nebraska' },
  { min: 88900, max: 89999, code: 'NV', name: 'Nevada' },
  { min: 3000, max: 3899, code: 'NH', name: 'New Hampshire' },
  { min: 7000, max: 8999, code: 'NJ', name: 'New Jersey' },
  { min: 87000, max: 88499, code: 'NM', name: 'New Mexico' },
  { min: 10000, max: 14999, code: 'NY', name: 'New York' },
  { min: 43000, max: 45999, code: 'OH', name: 'Ohio' },
  { min: 73000, max: 74999, code: 'OK', name: 'Oklahoma' },
  { min: 97000, max: 97999, code: 'OR', name: 'Oregon' },
  { min: 15000, max: 19699, code: 'PA', name: 'Pennsylvania' },
  { min: 300, max: 999, code: 'PR', name: 'Puerto Rico' },
  { min: 2800, max: 2999, code: 'RI', name: 'Rhode Island' },
  { min: 29000, max: 29999, code: 'SC', name: 'South Carolina' },
  { min: 57000, max: 57999, code: 'SD', name: 'South Dakota' },
  { min: 37000, max: 38599, code: 'TN', name: 'Tennessee' },
  { min: 75000, max: 79999, code: 'TX', name: 'Texas' },
  { min: 88500, max: 88599, code: 'TX', name: 'Texas' },
  { min: 84000, max: 84999, code: 'UT', name: 'Utah' },
  { min: 5000, max: 5999, code: 'VT', name: 'Vermont' },
  { min: 22000, max: 24699, code: 'VA', name: 'Virgina' },
  { min: 20000, max: 20599, code: 'DC', name: 'Washington DC' },
  { min: 98000, max: 99499, code: 'WA', name: 'Washington' },
  { min: 24700, max: 26999, code: 'WV', name: 'West Virginia' },
  { min: 53000, max: 54999, code: 'WI', name: 'Wisconsin' },
  { min: 82000, max: 83199, code: 'WY', name: 'Wyoming' },
]

// Try matching the region first and then zip code + country afterwards
export default (postalCode: string, region: string, country: string) => {
  let usStateByZipCode = undefined
  // Zip code + country check
  if (
    [
      'usa',
      'us',
      'u.s',
      'u.s.',
      'u.s.a',
      'u.s.a.',
      'united states',
      'united states of america',
    ].includes(country.trim().toLowerCase()) &&
    /^\d{5}(-\d{4})?$/.test(postalCode)
  ) {
    usStateByZipCode = states.find(
      (state) =>
        state.min <= parseInt(postalCode) && state.max >= parseInt(postalCode)
    )
  }

  const usStateByRegion = states.find(
    (state) =>
      region.toLowerCase() === state.name.toLowerCase() ||
      region.toLowerCase() === state.code.toLowerCase()
  )
  return usStateByRegion || usStateByZipCode
}
