import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import {
  Expense,
} from '@@/types'

const expenseFragment = gql`
  fragment ExpenseFragment on expense {
    id
    active_consignment
    contact_id
    currency
    inventory {
      id
      price
      discount
      url
    }
    label
    percentage: price_percentage
    price
    pricePercentageOf: price_percentage_of
  }
`

const useGetConsignedIn =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    inventoryIds = [],
  }: {
    inventoryIds: number[],
  }): Promise<Expense[]> => {
    const { data } = await client.query({
      query: gql`
        query getExpenses($inventoryIds: [Int]) {
            expense(where: {
              inventory_id: { _in: $inventoryIds }
              consignor_split: { _eq: true }
              label: { _eq: "consigned in" }
              contact_id: { _is_null: false }
              deleted: { _eq: false }
            }) {
              ...ExpenseFragment
            }
        }
        ${expenseFragment}
      `,
      variables: {
        inventoryIds,
      },
    })

    return camelcaseKeys(data.expense, {
      exclude: ['__typename']
    })
  }

export const useExpenseModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    getConsignedIn: useGetConsignedIn(client),
  }
}
