import gql from 'graphql-tag'
import { CrmContactFragment } from '../contact'

export const InventoryXContactFragment = gql`
  fragment InventoryXContactFragment on InventoryContact {
    contact {
      ...CrmContactFragment
    }
    status
    inventory_id: inventory {
      id
    }
    price
    currency
    __typename
  }
  ${CrmContactFragment}
`