import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { Payable, PayableRaw } from '@@/types'
// import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
// import { useInvoiceModel } from './invoice'

const payableFragment = gql`
  fragment PayableFragment on transaction {
    id
    title
    contact_id
    transaction_items {
      ...PayableItemFragment
    }
    cover_image_url
    inventory_count
    updated_at
    updated_by
    created_at
  }
`

const payableItemFragment = gql`
  fragment PayableItemFragment on transaction_item {
    inventory_id
    price
    expense_id
    label
    currency
    position
  }
`

const useGetByExpenseIds =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({ expenseIds = [] }: { expenseIds: number[] }): Promise<Payable[]> => {
    const { data } = await client.query({
      query: gql`
        query getPayables($expenseIds: [Int]) {
          transaction(
            where: {
              type: { _eq: "payable" }
              transaction_items: { expense_id: { _in: $expenseIds } }
              archived: { _eq: false }
              deleted: { _eq: false }
            }
          ) {
            ...PayableFragment
          }
        }
        ${payableFragment}
        ${payableItemFragment}
      `,
      variables: {
        expenseIds,
      },
    })

    return camelcaseKeys(data.transaction, {
      deep: true,
    }).map(({ __typename, ...otherFields }: PayableRaw) => ({
      ...otherFields,
    }))
  }

// const useCreate =
//   (client: ApolloClient<NormalizedCacheObject>) =>
//   async (
//     payables: Partial<Payable>[],
//     groupIds: string[]
//   ): Promise<Payable[]> => {
//     const hasuraPayables = payables.map((payable) => ({
//       type: 'payable',
//       title: payable.title,
//       transaction_items: {
//         data: snakecaseKeys(payable.transactionItems || [], {
//           deep: true,
//         }),
//       },
//       cover_image_url: payable.coverImageUrl,
//       inventory_count: payable.inventoryCount,
//       updated_at: payable.updatedAt,
//       updated_by: payable.updatedBy,
//     }))

//     const { data } = await client.mutate({
//       mutation: gql`
//         mutation createPayables($payables: [transaction_insert_input]!) {
//           insert_transaction(objects: $payables) {
//             returning {
//               ...PayableFragment
//             }
//           }
//         }
//         ${payableFragment}
//         ${payableItemFragment}
//       `,
//       variables: {
//         payables: hasuraPayables,
//       },
//     })

//     const permissions = groupIds.flatMap((id) => {
//       return data.insert_transaction.returning.map((transaction: Payable) => ({
//         access: '*',
//         groupId: id,
//         transactionId: transaction.id,
//       }))
//     })

//     const { createBatchInvoicePermission: createBatchPayablePermission } =
//       useInvoiceModel(client)
//     await createBatchPayablePermission(permissions)

//     return camelcaseKeys(data.insert_transaction.returning, { deep: true }).map(
//       ({ __typename, ...otherFields }: PayableRaw) => ({
//         ...otherFields,
//       })
//     )
//   }

export const usePayableModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    getByExpenseIds: useGetByExpenseIds(client),
    // create: useCreate(client),
  }
}
