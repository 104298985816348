import { Contact, Address } from '@@/types'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import axios from '@/plugins/axios'

const coreQbSyncObject = gql`
  fragment coreQbSyncObject on QbSyncObject {
    success
    error
    warnings
  }
`

const useGetQuickbooksCustomerId =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (
    customerContact: Contact,
    billingAddress: Address,
  ): Promise<string> => {
    const { data } = await client.query({
      query: gql`
        query getQuickbooksCustomers(
          $id: Int,
          $firstName: String!
          $lastName: String!
          $email: String!
          $phoneHome: String
          $phoneOffice: String
          $phoneMobile: String
          $phoneOther: String
          $addressStreet: String
          $addressCity: String
          $addressPostalCode: String
          $addressState: String
          $addressCountry: String
        ) {
          core {
            getQuickbooksCustomers(
              id: $id,
              validateContact: true,
              firstName: $firstName
              lastName: $lastName
              email: $email
              phoneHome: $phoneHome
              phoneOffice: $phoneOffice
              phoneMobile: $phoneMobile
              phoneOther: $phoneOther
              addressStreet: $addressStreet
              addressCity: $addressCity
              addressPostalCode: $addressPostalCode
              addressState: $addressState
              addressCountry: $addressCountry
              createIfNotExist: true
            ) {
              qbId
            }
          }
        }
      `,
      variables: {
        id: customerContact.id,
        firstName: customerContact.firstName,
        lastName: customerContact.lastName,
        email: customerContact.email,
        phoneHome: customerContact.phoneHome,
        phoneOffice: customerContact.phoneOffice,
        phoneMobile: customerContact.phoneMobile,
        phoneOther: customerContact.phoneOther,
        addressStreet: billingAddress.line1,
        addressCity: billingAddress.locality,
        addressPostalCode: billingAddress.postalCode,
        addressState: billingAddress.region,
        addressCountry: billingAddress.country,
      },
    })

    return data.core.getQuickbooksCustomers.at(0)?.qbId
  }

const useGetQuickbooksHomeCurrency =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<string> => {
    const { data } = await client.query({
      query: gql`
        query getQuickbooksHomeCurrency {
          core {
            getQuickbooksHomeCurrency
          }
        }
      `,
    })

    return data.core.getQuickbooksHomeCurrency
  }

const useGetTaxAgencies =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<string[]> => {
    const { data } = await client.query({
      query: gql`
        query getQuickbooksTaxAgencies {
          core {
            getQuickbooksTaxAgencies
          }
        }
      `,
    })

    return data.core.getQuickbooksTaxAgencies
  }

const useSyncInvoice =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (
    contactId: string,
    customerId: string,
    invoiceId: string,
    taxAgency: string
  ): Promise<{success: boolean, error: string, warnings: string[]}> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation syncQuickbooksInvoice(
          $contactId: Int!
          $customerId: Int!
          $invoiceId: Int!
          $taxAgency: String!
        ) {
          core {
            syncQuickbooks(
              contactId: $contactId
              customerId: $customerId
              invoiceId: $invoiceId
              taxAgency: $taxAgency
            ) { 

                success
                error
                warnings

            }
          }
        }
        ${coreQbSyncObject}
      `,
      variables: {
        contactId: parseInt(contactId),
        customerId: parseInt(customerId),
        invoiceId: parseInt(invoiceId),
        taxAgency,
      },
    })

    return data.core.syncQuickbooks
  }

const useGetIncompatiblePreferences = () => 
  async (): Promise<any> => {
    try {
      const prefCheck = await axios.get('/quickbooks/pref-check', {
        withCredentials: true,
        params: {}
      }).then(res => res?.data)

      return prefCheck || null
    } catch (err) {
      console.error(`${err}`)
    }
  }

export const useQuickbooksModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    getQuickbooksCustomerId: useGetQuickbooksCustomerId(client),
    useGetQuickbooksHomeCurrency: useGetQuickbooksHomeCurrency(client),
    getTaxAgencies: useGetTaxAgencies(client),
    syncInvoice: useSyncInvoice(client),
    getIncompatiblePreferences: useGetIncompatiblePreferences(),
  }
}
