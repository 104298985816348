/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client/core'
import auth from '@/auth'
import { useStore } from '@/store'

// @ts-ignore
const authMiddleware = new ApolloLink(async (operation, forward) => {
  const store = useStore()
  try {
    const accessToken = await auth.getAccessTokenSilently()
    store.auth0TokenExpired = false
    // @ts-ignore
    window.accessToken = accessToken
  } catch (e:any) {
    console.log(`error`, e?.message)
    store.auth0TokenExpired = true
  }

  operation.setContext({
    headers: {
      // @ts-ignore
      authorization: window.accessToken ? `Bearer ${window.accessToken}` : null,
    },
  })
  return forward(operation)
})

// Create an http link:
const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_CRM_API_URL}/graphql`,
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  name: 'invoices-web',
  connectToDevTools: process.env.NODE_ENV !== 'production',
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // link: ApolloLink.from([authMiddleware.concat(httpLink)]),
  link: authMiddleware.concat(httpLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
  },
})