import axios from 'axios'
import auth from '@/auth'
import { useStore } from '@/store'

const client = axios.create({
  baseURL: import.meta.env.VITE_CRM_API_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {},
})

export const authInterceptor = async (config: any) => {
  const store = useStore()
  try {
    if (auth) {
      const accessToken = await auth.getAccessTokenSilently()
      config.headers.authorization = `Bearer ${accessToken}`
      store.auth0TokenExpired = false
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.accessToken = accessToken
    } else {
      console.log('auth0 is not defined')
    }
  } catch (err: any) {
    console.log(`axios err.error`, err.error)
    // probably a legacy login, let it pass
    if (
      ['login_required', 'invalid_grant', 'mfa_required'].includes(err.error)
    ) {
      store.auth0TokenExpired = true
    } else if ('error_description' in err && err.error_description.includes('invalid')) {
      console.error('auth0', err.error_description)
      store.auth0TokenExpired = true
    }
  }

  return config
}

client.interceptors.request.use(authInterceptor)

client.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && 401 === error.response.status) {
      if (auth) auth.loginWithRedirect()
    } else {
      return Promise.reject(error)
    }
  },
)

export default client
