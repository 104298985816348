import { templater, safeFilename } from '@/utils'
import { saveAs } from 'file-saver'

const encodeHtml = (html: string) => encodeURIComponent(html)

export const getPdfFilename = (title: string, workflowStageName: string) => {
  if (workflowStageName) {
    return `${workflowStageName} - ${title}`
  } else {
    return title
  }
}

export const useDownloadPdf = (
  templateRaw: string,
  headerTemplateRaw = '',
  footerTemplateRaw = '',
  pageFormat = 'letter'
) => {
  const template = templater.compile(templateRaw)
  const headerTemplate = templater.compile(headerTemplateRaw)
  const footerTemplate = templater.compile(footerTemplateRaw)

  return async (
    filename: string,
    {
      context = {} as Record<string, unknown>,
      headerFooterContext = {} as Record<string, unknown>,
      authorization = '',
    } = {}
  ) => {
    const html = template(context)
    const headerHtml = headerTemplate(headerFooterContext ?? context)
    const footerHtml = footerTemplate(headerFooterContext ?? context)

    const res = await fetch(import.meta.env.VITE_RENDER_SERVICE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
      },
      body: JSON.stringify({
        body: encodeHtml(html),
        header: encodeHtml(headerHtml),
        footer: encodeHtml(footerHtml),
        encoding: 'url',
        options: {
          margin: {
            top: '1in',
            bottom: '1in',
          },
          format: pageFormat
        },
      }),
    })

    const { signedUrl }: { signedUrl: string } = await res.json()

    // HACK: file-saver sends HEAD instead of OPTIONS to check for CORS
    //       so get the file ourselves and assume CORS is set on the bucket
    const res2 = await fetch(signedUrl)
    const file = await res2.blob()

    saveAs(
      file,
      safeFilename(filename, 'pdf', { replacement: '_', fallback: 'invoice' })
    )
  }
}
