<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { Btn, ImgCropper } from '@/components'
import { s3upload } from '@/utils'
import { VueClassAttr } from '@@/types'
import { TrashIcon, ArrowsPointingInIcon } from '@heroicons/vue/24/outline'
import { useMixpanel } from '@/plugins/mixpanel'

const props = withDefaults(
  defineProps<{
    id?: string | null
    modelValue?: string | null
    uncroppedImgSrc?: string | null
    fallbackThumbnail?: string
    placeholder?: string
    placeholderClass?: VueClassAttr
    containerClass?: VueClassAttr
  }>(),
  {
    id: null,
    modelValue: null,
    uncroppedImgSrc: null,
    fallbackThumbnail:
      'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" /></svg>',
    placeholder: 'Click to browse or drop files here',
    placeholderClass: '',
    containerClass: '',
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', url: null | string): void
  (e: 'updateUncroppedImgSrc', url: null | string, type: null | string): void
}>()

const { getAccessTokenSilently } = useAuth0()
const mixpanel = useMixpanel()

const thumbnail = ref<null | string>(null)
const fileName = ref<null | string>(null)
const fileExt = ref<null | string>(null)
const hover = ref<boolean>(false)

const imgSrc = computed(() => props.uncroppedImgSrc || props.modelValue || '')

const loadImage = async (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = resolve
    img.onerror = reject
    img.src = url
  })
}

const deleteImage = async () => {
  if (props.id) {
    const identifierStart = props.id.lastIndexOf('-')
    const component = props.id.slice(identifierStart + 1)
    mixpanel.track(`Invoice Template remove ${component}`)
  }

  thumbnail.value = props.fallbackThumbnail
  emit('update:modelValue', null)
  emit('updateUncroppedImgSrc', null, componentType.value)
  return
}

const componentType = computed<string | null>(() => {
  if (props.id) {
    const identifierStart = props.id.lastIndexOf('-')
    const component = props.id.slice(identifierStart + 1)
    return component
  }
  return null
})
const headerOrFooter = computed(() => componentType.value && ['header', 'footer'].includes(componentType.value))

const fileChanged = async (e: Event) => {
  const target = e.target as HTMLInputElement

  // Bail out if no file
  if (!target.files || !target.files.length) {
    thumbnail.value = null
    emit('update:modelValue', null)
    emit('updateUncroppedImgSrc', null, componentType.value)
    return
  }

  const file = target.files[0]

  // Bail out if file is not an image
  if (!file.type.startsWith('image/')) {
    thumbnail.value = props.fallbackThumbnail
    emit('update:modelValue', null)
    emit('updateUncroppedImgSrc', null, componentType.value)
    return
  }

  uploadFileToS3(file, false)
}

const uploadFileToS3 = async (file: File, isCroppedImage: boolean) => {
  // set the thumbnail while we upload
  const url = URL.createObjectURL(file)

  try {
    await loadImage(url)
    thumbnail.value = url
  } catch {
    thumbnail.value = null
  }

  const accessToken = await getAccessTokenSilently()
  const s3url = await s3upload(file, { authorization: `Bearer ${accessToken}` })

  emit('update:modelValue', s3url)
  if (!isCroppedImage) {
    emit('updateUncroppedImgSrc', s3url, componentType.value)
  }
}

const hideThumbnail = () => (thumbnail.value = null)

const cropperVisible = ref(false)
const showCropper = () => {
  cropperVisible.value = true
}
const hideCropper = () => {
  cropperVisible.value = false
}

const setCroppedImage = (blob: Blob) => {
  const fileExtIndex = blob.type.lastIndexOf('/')
  const newFileType = fileExtIndex > -1 ? blob.type.slice(fileExtIndex + 1) : 'png'

  const fullFileName = [fileName.value, newFileType].join('.')
  const imgFile = new File([blob], fullFileName, { type: blob.type })

  uploadFileToS3(imgFile, true)
}

watch(
  () => props.modelValue,
  async (value) => {
    if (value) {
      await loadImage(value)

      const fileNameStart = value.lastIndexOf('/') + 1
      const fileNameEnd = value.lastIndexOf('.')

      fileName.value = value.slice(fileNameStart, fileNameEnd)
      fileExt.value = value.slice(fileNameEnd)
    } else {
      fileName.value = null
      fileExt.value = null
    }
    thumbnail.value = value
  },
  { immediate: true }
)
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<template>
  <div
    class="relative rounded border border-dashed border-gray-300 bg-white ring-primary-500/50 ring-offset-2 transition-colors focus-within:ring-2 hover:bg-gray-50"
    :class="containerClass"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <template v-if="!thumbnail">
      <slot :placeholder="placeholder">
        <div
          class="absolute inset-0 flex flex-col items-center justify-center gap-5 p-3 text-center text-gray-400"
        >
          <div v-if="$slots.icon">
            <slot name="icon" />
          </div>

          <div :class="placeholderClass">{{ placeholder }}</div>
        </div>
      </slot>
    </template>

    <template v-else>
      <div class="absolute right-0 items-center z-1" :style="{
        zIndex: 1,
        display: hover ? 'flex' : 'none',
      }">
        <div>
          <Btn v-if="headerOrFooter" variant="primary" outline class="mr-3 mt-3 flex items-center gap-1" size="sm" @click="showCropper">
            <ArrowsPointingInIcon class="w-5" />
            {{ $t('actions.crop') }}
          </Btn>
        </div>

        <div>
          <Btn variant="danger" outline class="mr-3 mt-3 flex items-center gap-1" size="sm" @click="deleteImage">
            <TrashIcon class="w-5" />
            {{ $t('actions.remove') }}
          </Btn>
        </div>
      </div>
      <slot name="thumbnail">
        <div class="absolute inset-0 flex items-center justify-center p-3">
          <img
            :src="thumbnail"
            :onerror="hideThumbnail"
            class="max-h-full max-w-full object-contain"
          />

          <div
            v-if="fileName"
            class="absolute inset-x-3 bottom-3 flex text-center"
          >
            <div
              class="mx-auto flex max-w-full rounded bg-black/50 px-2 text-sm text-white"
            >
              <span class="truncate">{{ fileName }}</span>
              <span>{{ fileExt }}</span>
            </div>
          </div>
        </div>
      </slot>
    </template>

    <input
      type="file"
      class="xs:h-full z-10 h-32 h-32 cursor-pointer opacity-0"
      v-bind="$attrs"
      @change="fileChanged"
    />

    <ImgCropper
      v-if="imgSrc"
      ref="quickbooksDialogRef"
      :show="cropperVisible"
      :img-src="imgSrc"
      @crop="setCroppedImage"
      @cancel="hideCropper"
      @close="hideCropper"
    />
  </div>
</template>
