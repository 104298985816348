<script setup lang="ts">
import {
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
  shallowRef,
  watch,
} from 'vue'
import { debouncedWatch } from '@vueuse/core'
import { templater } from '@/utils'

const props = defineProps<{
  template: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any
}>()

const preview = ref<HTMLIFrameElement | null>(null)

let compiled = shallowRef<ReturnType<typeof templater.compile> | null>(null)

const compileTemplate = (template: string) => {
  try {
    compiled.value = templater.compile(template)
    // try a dry run render since this is where the errors are thrown from
    compiled.value({})
  } catch (err) {
    compiled.value = null
  }
}

watch(
  () => props.template,
  (value) => compileTemplate(value),
  { immediate: true }
)

const html = computed(() =>
  compiled.value ? compiled.value(props.context) : ''
)

debouncedWatch(
  () => html.value,
  (value) => updatePreview(value),
  { debounce: 500 }
)

const updateHeight = async () => {
  if (!preview.value) return

  preview.value.style.height = '0'
  preview.value.style.height = `${
    preview.value.contentDocument?.documentElement?.scrollHeight ?? 0
  }px`
}

// https://gomakethings.com/debouncing-events-with-requestanimationframe-for-better-performance/
let debouncer: ReturnType<typeof requestAnimationFrame> | null = null
const updateHeightDebounce = () => {
  if (debouncer) cancelAnimationFrame(debouncer)

  debouncer = requestAnimationFrame(() => {
    debouncer = null
    updateHeight()
  })
}

const resizeObserver = new ResizeObserver(updateHeightDebounce)

onMounted(() => {
  if (!preview.value) return
  resizeObserver.observe(preview.value)

  updatePreview(html.value)
})

onBeforeUnmount(() => {
  resizeObserver.disconnect()
})

const updatePreview = async (value: string) => {
  if (!preview.value) return

  const blob = new Blob([value], { type: 'text/html' })
  const url = URL.createObjectURL(blob)

  preview.value.src = url
}
</script>

<template>
  <iframe ref="preview" scrolling="no" @load="updateHeightDebounce" />
</template>
