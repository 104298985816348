import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { Activity, InventoryXContactInput, InventoryXContact } from '@@/types'
import { CrmContactFragment, InventoryXContactFragment } from '.'

const activityFragment = gql`
  fragment ActivityFragment on activity {
    type
    action
    text
    status_text
    contact_id
    link_type
    link_id
    link_inventory_id
    link_transaction_id
    filter_type
    status
    min_time_interval
    max_time_interval
    contacts_count
    note
    date_assigned
    field
  }
`

const useCreate =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (activities: Activity[]): Promise<Activity[]> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation InsertActivity($activities: [activity_insert_input]!) {
          insert_activity(objects: $activities) {
            returning {
              ...ActivityFragment
            }
          }
        }

        ${activityFragment}
      `,
      variables: {
        activities: snakecaseKeys(activities, { deep: true }),
      },
    })

    return camelcaseKeys(data.insert_activity, {
      deep: true,
    })
  }

const useCreateInventoryXContactLog =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (logs: InventoryXContactInput[]): Promise<InventoryXContact[]> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation InsertInventoryXContact(
          $logs: [inventory_x_contact_insert_input]!
        ) {
          insert_inventory_x_contact(objects: $logs) {
            returning {
              ...InventoryXContactFragment
            }
          }
        }

        ${CrmContactFragment}
        ${InventoryXContactFragment}
      `,
      variables: {
        logs: snakecaseKeys(logs, { deep: true }),
      },
    })

    return camelcaseKeys(data.insert_inventory_x_contact.returning, {
      deep: true,
    })
  }

const useEditContact =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    invoiceId,
    contactEdits = {},
  }: {
    invoiceId: number
    contactEdits: {
      contact_id?: number
      shipped_contact_id?: number
    }
  }): Promise<number> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation editContact(
          $invoiceId: Int!
          $transaction: coreTransactionInput!
        ) {
          core {
            editTransactionContact(id: $invoiceId, transaction: $transaction) {
              contact {
                id
              }
              shippedContact {
                id
              }
            }
          }
        }
      `,
      variables: {
        invoiceId,
        transaction: contactEdits,
      },
    })

    return data.core.editTransactionContact
  }

export const useActivityModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    editContact: useEditContact(client),
    create: useCreate(client),
    createInventoryXContactLog: useCreateInventoryXContactLog(client),
  }
}
