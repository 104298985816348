import gql from 'graphql-tag'

export const transactionItem = gql`
  fragment TransactionItem on TransactionItem {
    inventory_id: inventory {
      id
    }
    price
    expense_id
    label
    currency
    discount
    discount_percent
    description
    hide_price
    position
  }`