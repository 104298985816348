import gql from 'graphql-tag'

export const transactionOverview = gql`
fragment TransactionOverview on Transaction {
  id
  type
  transaction_number
  title
  date_out
  cover_image_url
  contact_id: contact {
    id
  }
  currency
  paid_status
  status
  subtotal_price
  total_price
  inventory_count
  payment_method
  created_at
  created_by
  updated_at
  updated_by
}
`
