<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import camelcaseKeys from 'camelcase-keys'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import {
  Btn,
  Loading,
  PageHeader,
  PreviewPanel,
  SkeletonText,
} from '@/components'
import { useMixpanel } from '@/plugins/mixpanel'
import { emptyInvoice, emptyTemplate, excludeCase } from '@/models'
import { useDownloadPreview } from '@/composables/preview'
import { formatDate, useTemplateFile, transformCrmInvoice } from '@/utils'
import { Invoice, InvoiceTemplate } from '@@/types'
import affirmLogoWhite from '@/assets/images/logo-affirm-white.svg'
import achLogoWhite from '@/assets/images/logo-ach-white.svg'

import { onMounted } from 'vue'

const props = defineProps<{
  shareId: string
  templateId?: string
}>()

onMounted(() => {
  window.Intercom('update', {
    hide_default_launcher: true,
  })
})

const router = useRouter()
const route = useRoute()
const mixpanel = useMixpanel()

const invoice = ref<Invoice>(emptyInvoice())

const template = ref<InvoiceTemplate>(emptyTemplate())

const loading = ref(false)
const isArchived = ref<boolean>(false)

const isStripePayment = ref<boolean>(false)
const isCCPayment = ref<boolean>(false)
const isAffirmPayment = ref<boolean>(false)
const isAchPayment = ref<boolean>(false)
const isPaid = ref<boolean>(false)
const datePaid = ref<Date>()

const fetchInvoice = async () => {
  try {
    loading.value = true
    // ensure the function is always called from the same host for CORS
    const reviewServiceUrl = new URL(import.meta.env.VITE_REVIEW_SERVICE_URL)
    reviewServiceUrl.hostname = window.location.hostname

    const invoiceRes = await fetch(reviewServiceUrl.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shareId: props.shareId,
        passcode: route.query.passcode?.toString() ?? '',
        ...(props.templateId && { templateId: props.templateId }),
      }),
    })
    const data = await invoiceRes.json()
    invoice.value = transformCrmInvoice(
      camelcaseKeys(data.transaction[0], {
        deep: true,
        exclude: excludeCase,
      })
    )

    template.value = camelcaseKeys(data.transaction_template?.[0], {
      deep: true,
      exclude: ['__typename'],
    })

    loading.value = false

    isAffirmPayment.value =
      invoice.value?.paymentProviderId?.includes('affirm') || false
    isCCPayment.value =
      invoice.value?.paymentProviderId?.includes('card') || false
    isAchPayment.value =
      invoice.value?.paymentProviderId?.includes('us_bank_account') || false
    isStripePayment.value =
      isAffirmPayment.value || isCCPayment.value || isAchPayment.value
    isPaid.value = data.transaction[0].paid_status === 'paid'
    datePaid.value = data.transaction[0]?.payment_history[0]?.date
    isArchived.value = invoice.value.archived || false
  } catch (e) {
    router.replace({
      name: 'review-verify',
      params: { shareId: props.shareId, templateId: props.templateId },
    })
  }
}
fetchInvoice()

const layoutFilename = computed(
  () =>
    template.value?.layoutFilename ||
    invoice.value.template?.layoutFilename ||
    'default'
)

const templateRaw = computed(() =>
  loading.value ? '' : useTemplateFile(layoutFilename.value)
)

const overrides = computed(() => ({
  editing: false,
  ...(props.templateId && { template: template.value }),
}))

const { previewContext, download, downloading } = useDownloadPreview(
  invoice,
  overrides
)

const downloadPdf = () => {
  download(layoutFilename.value)
  mixpanel.track('Download PDF - Client facing')
}

document.title = invoice.value.title || "View Invoice"
</script>

<template>
  <div v-if="!isArchived">
    <PageHeader :title="invoice.title" class="mx-auto mb-8 max-w-[8.5in]">
      <template #title>
        <SkeletonText v-if="loading" class="mb-1 !block text-base" />
        <div v-if="!loading">{{ invoice.title }}</div>

        <div
          v-if="
            !loading &&
            invoice.savedAt &&
            (invoice.updatedBy || invoice.createdBy)
          "
          class="mb-1 text-base font-normal text-secondary-400"
        >
          {{
            $t('messages.invoiceUpdatedBy', {
              name: invoice.updatedBy || invoice.createdBy,
              date: formatDate(invoice.savedAt),
            })
          }}
        </div>

        <SkeletonText v-if="loading" class="mb-1 !block text-base" />
      </template>

      <template #default>
        <div
          class="w-full flex-wrap items-center justify-end gap-2 space-y-3 md:w-1/3 lg:flex lg:space-y-0"
        >
          <div v-if="isStripePayment" class="flex w-full flex-col gap-2">
            <Btn
              v-if="isCCPayment && !isPaid && !(route.query.success === 'true')"
              :disabled="loading"
              variant="custom"
              :href="`https://api.arternal.com/stripeCheckoutInvoice/${invoice.shareId}/card`"
              class="h-[42px] w-full bg-sky-500 text-center text-white hover:bg-sky-600 focus:bg-sky-600"
            >
              <div class="flex items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                  />
                </svg>

                <span>{{ $t('actions.pay') }}</span>
              </div>
            </Btn>
            <Btn
              v-if="
                isAffirmPayment && !isPaid && !(route.query.success === 'true')
              "
              variant="custom"
              :disabled="loading"
              :href="`https://api.arternal.com/stripeCheckoutInvoice/${invoice.shareId}/affirm`"
              class="h-[42px] w-full bg-[#4a49f5] text-center text-white hover:bg-[#3130E0] focus:bg-[#3130E0]"
            >
              <div class="flex items-center justify-center gap-2">
                <img :src="affirmLogoWhite" class="mx-1 h-5" />
                <span>{{ $t('actions.payOverTime') }}</span>
              </div>
            </Btn>
            <Btn
              v-if="
                isAchPayment && !isPaid && !(route.query.success === 'true')
              "
              :disabled="loading"
              variant="custom"
              :href="`https://api.arternal.com/stripeCheckoutInvoice/${invoice.shareId}/us_bank_account`"
              class="focus:bg-green-500] h-[42px] w-full bg-green-500 text-center text-white hover:bg-green-600"
            >
              <div class="flex items-center justify-center gap-2">
                <img :src="achLogoWhite" class="mx-1 h-5" />
                <span>{{ $t('actions.payViaAch') }}</span>
              </div>
            </Btn>
            <div></div>
          </div>
          <Btn
            id="dpButton"
            variant="secondary"
            outline
            class="flex flex-row items-center justify-center gap-2"
            :class="{
              'h-[42px] w-full text-center': isStripePayment,
            }"
            :disabled="loading"
            @click="downloadPdf"
          >
            <Loading v-if="downloading" />
            <ArrowDownTrayIcon v-else class="h-5 w-5" />
            <span>{{ $t('actions.downloadPdf') }}</span>
          </Btn>
        </div>
      </template>
    </PageHeader>

    <div class="mx-auto mb-8 max-w-[8.5in]">
      <div v-if="isPaid">
        <div
          class="mb-4 flex flex-row rounded-lg bg-green-100 p-3 text-sm text-green-700"
          role="alert"
        >
          <div class="flex w-full flex-row items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="m-2 h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span class="float-left font-bold">
              {{ $t('messages.invoicePaid') }}:
              {{ datePaid && formatDate(datePaid) }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="route.query.success === 'true'">
        <div
          class="rounded-md rounded-b border-t-4 border-lime-500 bg-green-100 px-4 py-3 text-lime-900"
          role="alert"
        >
          <div class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="m-2 h-8 w-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <div>
              <p class="font-bold">
                {{ $t('messages.invoiceSuccessHeading') }}
              </p>
              <p class="text-sm">{{ $t('messages.invoiceSuccessBody') }}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto max-w-[8.5in] rounded border">
      <div v-if="loading" class="flex h-64 items-center justify-center">
        <Loading class="mr-2" />
        {{ $t('messages.wait') }}
      </div>

      <PreviewPanel
        v-else
        :template="templateRaw"
        :context="previewContext"
        class="mx-auto min-h-[11in] w-full max-w-[8.5in]"
      />
    </div>
  </div>

  <div v-else class="my-12 text-center">
    <p>⚠️ This invoice is no longer available</p>
  </div>

  <div class="mt-6 flex flex-row items-center justify-center gap-2">
    <p class="text-sm text-secondary-500">Invoice powered by</p>
    <a href="https://arternal.com" target="_blank">
      <img
        src="https://invoice.arternal.com/images/arternal-logo-2021-black.png"
        alt="Arternal"
        class="text-center"
        width="98"
    /></a>
  </div>
</template>
