import { formatPercent, findCountry, findUsState, cleanJoin } from '@/utils'
import { Address } from '@@/types'

export default (
  taxPercent: number,
  address?: Address,
  hideLocality?: boolean
) => {
  const details = [
    hideLocality !== true && address?.locality,

    // make sure country and region are defined before we try to resolve them
    // otherwise fall back to the current region value
    address?.country &&
    address?.region &&
    address?.postalCode &&
    findCountry(address.country)?.code.toLowerCase() === 'us'
      ? findUsState(address.postalCode, address.region, address.country)?.code
      : address?.region,

    formatPercent(taxPercent),
  ]

  return cleanJoin(details, ', ')
}
