<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Btn, PageHeader } from '@/components'

const props = defineProps<{
  shareId: string
  templateId?: string
}>()

const router = useRouter()

const passcode = ref('')

const submit = async () => {
  router.replace({
    name: 'review',
    query: { passcode: passcode.value },
    params: { templateId: props.templateId },
  })
}

document.title = "Passcode Required"
</script>

<template>
  <div class="mx-auto mt-11 max-w-[480px]">
    <PageHeader
      :title="$t('pages.invoiceVerify.title')"
      class="mb-8 text-center"
    />

    <div class="mb-9 text-center text-lg text-secondary-500">
      This invoice requires a passcode to be viewed
    </div>

    <div>
      <form @submit.prevent="submit">
        <div class="mb-5">
          <label for="passcode" class="sr-only">Passcode</label>
          <input
            id="passcode"
            v-model="passcode"
            type="password"
            class="mb-1"
            :placeholder="$t('forms.passcode.passcodePlaceholder')"
            autocomplete="off"
          />
        </div>

        <Btn class="w-full" variant="primary">Submit</Btn>
      </form>
    </div>
  </div>
</template>
