// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Plugin } from 'vue'
import auth from '@/auth'
import { client } from '@/plugins/model'
import { useAccountModel} from '@/models'
import { cleanJoin } from '@/utils'

export function createIntercom(
  settings: Intercom_.IntercomSettings = {}
): Plugin {
  return {
    install: async () => {
      // Disable plugin with no app_id
      if (!settings.app_id) return

      await auth.checkSession()
      if (auth.isAuthenticated.value) {

        if (process.env.NODE_ENV === 'production') {
          const accountModel = useAccountModel(client)
          const account = await accountModel.get()

          settings.email = account.email
          settings.name = cleanJoin([account.firstName, account.lastName])
          settings.user_id = account.id
        }
      
        window.intercomSettings = settings
      }

      ;(function () {
        const w = window
        const ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          const d = document
          const i = function (...args) {
            i.c(args)
          }
          i.q = []
          i.c = function (args) {
            i.q.push(args)
          }
          w.Intercom = i
          const l = function () {
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + settings.app_id
            const x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
    },
  }
}


