import gql from 'graphql-tag'

export const CrmContactFragment = gql`
  fragment CrmContactFragment on ContactJoin {
    id
    first_name
    last_name
    email
    phone_home
    phone_mobile
    phone_office
    phone_other
    primary_address2
    primary_city
    primary_state
    primary_country
    primary_street
    primary_zip
    image_url
    honorific
    extra
    kyc_verified
    __typename
  }
`