import gql from 'graphql-tag'
import { CrmInvoiceFragment } from '@/fragments'

export const getInvoiceQuery = gql`
query getInvoice($id: Int!) {
  getInvoice(id: $id) {
    ...CrmInvoiceFragment
  }
}
${CrmInvoiceFragment}
`