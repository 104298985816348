import isPlainObject from './isPlainObject'

const deepOmit = (input: unknown, omit: string | string[]): unknown => {
  const omits = Array.isArray(omit) ? omit : [omit]

  if (Array.isArray(input)) {
    return input.map((item) => deepOmit(item, omits))
  } else if (isPlainObject(input)) {
    return Object.fromEntries(
      Object.entries(input as Record<string, unknown>)
        .filter(([key]) => !omits.includes(key))
        .map(([key, value]) => [key, deepOmit(value, omits)])
    )
  } else {
    return input
  }
}

export default deepOmit
