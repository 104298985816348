import { accountSetting } from '@/utils'
import { Account } from '@@/types'

export default (
  account: Account,
  defaultCurrencies = ['USD', 'CAD', 'EUR']
) => {
  const selected = accountSetting(account, 'selectedCurrencies')
    .split(',')
    .filter(Boolean)

  // if account has no currencies, return defaults
  if (selected.length === 0) {
    selected.push(...defaultCurrencies)
  }

  const local = accountSetting(account, 'localCurrency')

  // ensure local exists in currencies list
  if (local && !selected.includes(local)) {
    selected.push(local)
  }

  return {
    selected,
    // if local not set, default to first currency
    local: local || selected[0],
  }
}
