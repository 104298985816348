import linkifyHtml from 'linkify-html'
const linkOptions = { defaultProtocol: 'https', target: '_blank' }

export default (markup: string, context: Record<string, unknown> = {}) => {
  try {
    return linkifyHtml(
      markup
        .replace(/(.*?)\[(.*?)\](\n)?/gims, (_, before, match, newline) => {
          const captionValue = `${context[match.replace(/\n+/g, '')] ?? ''}`
          const value = `${before}${captionValue}`
          return (before.replace(/.*\n+/g, '') || captionValue) && newline
            ? `${value}\n`
            : value
        })
        .replace(/\n/g, '<br/>')
        .replace(
          /\*(.*?)\*/gims,
          (_, match) => `<strong>${match.replace(/\n+/g, '')}</strong>`
        )
        .replace(
          /_(.*?)_/gims,
          (_, match) => `<em>${match.replace(/\n+/g, '')}</em>`
        ),
      linkOptions
    )
  } catch {
    return markup
      .replace(/(.*?)\[(.*?)\](\n)?/gims, (_, before, match, newline) => {
        const captionValue = `${context[match.replace(/\n+/g, '')] ?? ''}`
        const value = `${before}${captionValue}`
        return (before.replace(/.*\n+/g, '') || captionValue) && newline
          ? `${value}\n`
          : value
      })
      .replace(/\n/g, '<br/>')
      .replace(
        /\*(.*?)\*/gims,
        (_, match) => `<strong>${match.replace(/\n+/g, '')}</strong>`
      )
      .replace(
        /_(.*?)_/gims,
        (_, match) => `<em>${match.replace(/\n+/g, '')}</em>`
      )
  }
}
