<script setup lang="ts">
import {
  DashboardIcon,
  SmartMailIcon,
  ContactsIcon,
  ReportsIcon,
  DealsIcon,
  InventoryIcon,
  InvoicesIcon,
  TransactionIcon,
  ArternalIcon,
  PaymentsIcon,
} from 'arternal-ui/src/icons'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import AvatarDropdown from 'arternal-ui/src/components/Dropdown/AvatarDropdown.vue'
// import { useFeatureFlagModel } from './models'
// import { useModelClient } from './plugins/model'
import { useStore } from '@/store'
import { watch } from 'vue'

withDefaults(
  defineProps<{
    avatarPicture?: string;
  }>(),
  {
    avatarPicture: '',
  }
);

const mainStore = useStore()
const { featureFlags } = storeToRefs(mainStore)
const transactionsEnabled = ref(false)
const invoiceV2Enabled = ref(false)
const dealsEnabled = ref(false)
const paymentsEnabled = ref(false)
const reportingEnabled = ref(false)
watch(() => featureFlags.value, () => {
  transactionsEnabled.value = featureFlags.value.some((flag) => flag.name === 'Invoice_default')
  invoiceV2Enabled.value = featureFlags.value.some((flag) => flag.name === 'InvoiceV2')
  dealsEnabled.value = featureFlags.value.some((flag) => flag.name === 'Deals_BN')
  paymentsEnabled.value = featureFlags.value.some((flag) => flag.name === 'payments_dashboard')
  reportingEnabled.value = featureFlags.value.some((flag) => flag.name === 'Reporting')
}, { immediate: true })
// const modelClient = useModelClient()
// const featureFlagModel = useFeatureFlagModel(modelClient)

const unseenChanges = ref<number>(0)
// featureFlagModel.all().then((flags) => {
//   mainStore.featureFlags = flags
// })
</script>

<template>
  <aside class="sticky top-0 z-50 h-screen bg-zinc-800 p-1 w-[68px]">
    <a href="/dashboard" class="mt-1 mb-2 block">
      <img :src="ArternalIcon" draggable="false" class="mx-auto" />
    </a>

    <nav class="overflow-y-auto text-center font-medium">
      <a
        href="/dashboard"
        class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600"
        data-tooltip-target="tooltip-default"
      >
        <img :src="DashboardIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.home') }}</span>
      </a>

      <a href="/offers" class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600">
        <img :src="SmartMailIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.mail') }}</span>
      </a>

      <a href="/contacts/all" class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600">
        <img :src="ContactsIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.contacts') }}</span>
      </a>

      <a
        href="/inventory/artist/allinventory"
        class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600"
      >
        <img :src="InventoryIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.inventory') }}</span>
      </a>

      <a v-if="transactionsEnabled" href="/transactions" class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600">
        <img :src="TransactionIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.transactions') }}</span>
      </a>

      <RouterLink
         v-if="invoiceV2Enabled"
        :to="'/invoices'"
        class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600 active"
      >
        <img :src="InvoicesIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.invoices') }}</span>
      </RouterLink>

      <a 
        v-if="dealsEnabled" 
        href="/deals" class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600">
        <img :src="DealsIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.deals') }}</span>
      </a>

      <a
        v-if="paymentsEnabled"  
        href="/payments" class="hover:bg-zinc-600 block py-1.5 px-1 rounded-sm">
        <img :src="PaymentsIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.payments') }}</span>
      </a>

      <a
        v-if="reportingEnabled"  
        href="/reporting" class="block rounded-sm py-1.5 px-1 hover:bg-zinc-600">
        <img :src="ReportsIcon" draggable="false" />
        <span class="text-zinc-300">{{ $t('sidebar.reports') }}</span>
      </a>
    </nav>

    <div class="fixed bottom-0 pb-2 ml-[4px]">
      <span
        v-if="unseenChanges"
        class="unseen-changes rounded-full bg-red-700 text-white"
        >{{ unseenChanges }}</span>
      <AvatarDropdown :picture-override="avatarPicture" />
    </div>
  </aside>
</template>

<style scoped>
/* Hide scrollbars when the menu goes larger than screen size */
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: calc(100vh - 150px); /* remove logo size and user profile size */
}

nav a {
  text-align: center;
  text-decoration: none;
  font-weight: 600
}

nav a img {
  width: 26px;
  margin: 3px auto -3px auto;
}

nav a span {
  margin: 0 0 3px 0;
  font-size: 10px;
  word-wrap: break-word;
  text-align: center;
}

nav a,
nav a:hover,
nav a.active {
  transition: ease-in-out 300ms;
}

nav a.active {
  background: #52525b;
}
nav a.active img {
  filter: brightness(0) saturate(100%) invert(99%) sepia(17%) saturate(3100%)
    hue-rotate(337deg) brightness(107%) contrast(90%);
}
nav a.active span {
  color: #e6fc6f;
}

.unseen-changes {
  font-size: 11px;
  width: 20px;
  display: block;
  text-align: center;
  padding: 2px;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
}

:deep(.avatar):hover {
    border: 2px solid #E6FC6F;
    padding: 2px;
    overflow: hidden;
  }
</style>
