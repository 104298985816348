import { cleanJoin } from '@/utils'
import { Contact } from '@@/types'

export default (contact: Contact) => {
  const lines = []
  if (contact.firstName || contact.lastName) {
    lines.push(
      cleanJoin([
        contact.honorific ?? '',
        cleanJoin([
          contact.firstName,
          contact.middleName,
          contact.lastName,
          contact.pronouns ? `(${contact.pronouns})` : '',
        ]),
      ])
    )
  }
  if (contact.attn) {
    lines.push(contact.attn)
  }
  if (contact.phoneHome) {
    lines.push(contact.phoneHome)
  }
  if (contact.email) {
    lines.push(contact.email)
  }
  return lines
}
