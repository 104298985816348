<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { Head } from '@vueuse/head'
import { LocationQuery, useRoute, useRouter } from 'vue-router'
import { useCssVar } from '@vueuse/core'
import { useModelClient } from '@/plugins/model'
import { useApolloClient } from '@vue/apollo-composable'
import { useAccountModel, /* useFeatureFlagModel ,*/ useOrganizationModel } from '@/models'
import { useDimension } from '@/composables'
import AuthReloadModel from '@/pages/auth/AuthReloadModal.vue'
import {
  ArchiveBoxIcon,
  BanknotesIcon,
  FlagIcon,
  RectangleStackIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  EyeIcon,
  HomeIcon,
  SparklesIcon,
  BuildingStorefrontIcon,
  Bars3Icon,
  PaperAirplaneIcon,
  RectangleGroupIcon,
  UserCircleIcon,
  CreditCardIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline'
import { Account, Organization } from '@@/types'
import { allFeatureFlagsQuery } from '@/queries'
import { Logo } from '@/components'
import AppNavbar from '@/AppNavbar.vue'
import { useStore } from '@/store'

const mainStore = useStore()
const router = useRouter()
const route = useRoute()
const { isAuthenticated, idTokenClaims } = useAuth0()

const modelClient = useModelClient()
const accountModel = useAccountModel(modelClient)
const organizationModel = useOrganizationModel(modelClient)
const account = ref<Account | null>(null)
const organization = ref<Organization[]>([])
const stripeConnected = ref(false)
const arternalPayEnabled = ref(false)

const { resolveClient } = useApolloClient()
const crmClient = resolveClient()

watch(isAuthenticated, async (authenticated) => {
  if (authenticated) {
    account.value = await accountModel.get()
    organization.value = await organizationModel.search()
    stripeConnected.value = organization.value[0].stripeAccountStatus === 'complete'
    mainStore.featureFlags = await crmClient.query({
      query: allFeatureFlagsQuery,
    }).then((response) => response.data.featureFlag)
    arternalPayEnabled.value = mainStore.featureFlags.some((flag) => flag.name === 'payment_flag')
  }
})

const menuVisible = ref(false)

const toggleMenu = (value?: boolean | MouseEvent) => {
  menuVisible.value = typeof value === 'boolean' ? value : !menuVisible.value
}

// routes with meta.chromeVisible set must NOT be lazy loaded
const chromeVisible = computed(() => route.meta.chromeVisible !== false)
// const featureFlagModel = useFeatureFlagModel(modelClient)
const isUniversalNavbarEnabled = true

const crmLink = ref(import.meta.env.VITE_CRM_URL)
const nexusLink = ref(import.meta.env.VITE_CRM_NEXUS_URL)
const posLink = ref(import.meta.env.VITE_POS_URL)
const stripeLink = 'https://dashboard.stripe.com'

// wait for the router to async resolve to show nav
const routerReady = ref(false)
const checkReady = async () => {
  await router.isReady()
  routerReady.value = true
}
checkReady()

const fullName = computed(() => {
  const name = [
    idTokenClaims.value?.given_name,
    idTokenClaims.value?.family_name,
  ].join(' ')
  return name.trim() || idTokenClaims.value?.nickname || ''
})



// prevent body scrolling when modal elements (sidebars, etc.) are visible
watch(
  () => menuVisible.value,
  (value) => document.body.classList.toggle('overflow-y-hidden', value)
)

// track header as css var to allow position sticky offsets
const { ref: heightRef, dimension: heightDim } = useDimension('height')
const headerHeight = useCssVar('--header-height', document.documentElement)

const isInvoiceLinkActive = (query: LocationQuery) => {
  if (route.name !== 'invoices') {
    return false
  }

  if (!query.status?.length) {
    return !route.query.status?.length
  }

  if (typeof route.query.status === 'string') {
    return query.status[0] === route.query.status
  }
  else if (Array.isArray(route.query.status)) {
    return query.status[0] === route.query.status[0]
  }
}
watch(
  () => heightDim.value,
  (value) => (headerHeight.value = value.toString() + 'px')
)
</script>

<template>
  <AuthReloadModel v-if="chromeVisible" />
  <Head>
    <title>{{ $t('title') }}</title>
  </Head>

  <div class="relative flex min-h-screen">
    <header
      v-if="chromeVisible"
      :ref="heightRef"
      class="tranform fixed inset-x-0 z-20 flex justify-between bg-zinc-800 text-white lg:hidden"
    >
      <router-link
        :to="{ name: 'home' }"
        class="px-5 py-3 text-white !no-underline"
      >
        <Logo />
      </router-link>

      <button class="p-5" @click="toggleMenu">
        <Bars3Icon class="h-6 w-6" />
      </button>
    </header>

    <button
      v-if="chromeVisible"
      class="fixed inset-x-0 z-20 hidden h-screen w-screen bg-black/50"
      :class="{ '!block lg:!hidden': menuVisible }"
      @click="toggleMenu(false)"
    />

    <aside
      v-if="chromeVisible && mainStore.featureFlags.length"
      class="fixed inset-y-0 left-0 z-30 flex flex-shrink-0 overflow-y-auto overflow-x-hidden bg-zinc-100 transition duration-200 ease-in-out"
      :class="{
        '-translate-x-full lg:-translate-x-0': !menuVisible,
        'w-64': !isUniversalNavbarEnabled,
        'w-80': isUniversalNavbarEnabled,
      }"
    >
      <AppNavbar v-if="isUniversalNavbarEnabled" :avatar-picture="account?.imageUrl || ''" />

      <div v-if="routerReady">
        <nav class="flex flex-col px-[0.35rem] pt-3">
          <router-link
            v-if="!isUniversalNavbarEnabled"
            :to="{ name: 'home' }"
            class="sticky top-0 bg-zinc-100 px-5 pb-3 font-semibold text-zinc-800 !no-underline hover:text-blue-800"
          >
            <Logo />
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices' }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="mb-2 flex rounded-lg px-5 py-3 text-zinc-800 hover:bg-blue-200 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <DocumentTextIcon class="ml-1 mr-4 h-6 w-6" />
              {{ $t('nav.invoices.index') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['draft'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <RectangleStackIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.draft') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['sent'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <PaperAirplaneIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.sent') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['viewed'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <EyeIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.viewed') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['paid'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <CurrencyDollarIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.paid') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['partially-paid'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >              
              <BanknotesIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.partiallyPaid') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['unpaid'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <FlagIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.unpaid') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['archived'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <ArchiveBoxIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.archived') }}
            </div>
          </router-link>

          <router-link
            v-slot="{ route: linkRoute }"
            :to="{ name: 'invoices', query: { status: ['voided'] } }"
            class="!no-underline"
            @click="toggleMenu(false)"
          >
            <div 
              class="flex rounded-lg px-5 py-2 text-sm text-zinc-800 hover:bg-blue-100 hover:text-blue-800"
              :class="{ '!bg-blue-200 text-zinc-800 font-semibold' : isInvoiceLinkActive(linkRoute.query) }"
            >
              <XCircleIcon class="ml-6 mr-4 h-5 w-5" />
              {{ $t('nav.invoices.voided') }}
            </div>
          </router-link>

          <hr class="my-2">

          <router-link
            :to="{ name: 'templates' }"
            class="flex rounded-lg px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-200 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
            active-class="!bg-blue-200 text-zinc-800 font-semibold"
            @click="toggleMenu(false)"
          >
            <RectangleGroupIcon class="ml-1 mr-4 h-6 w-6" />

            {{ $t('nav.templates.index') }}
          </router-link>

          <a
            v-if="!isUniversalNavbarEnabled"
            :href="crmLink"
            target="_blank"
            class="flex rounded-lg px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-100 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
          >
            <HomeIcon class="ml-1 mr-4 h-6 w-6" />
            {{ $t('nav.arternal.crm') }}
          </a>
          <a
            :href="nexusLink"
            class="flex rounded-lg px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-100 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
          >
            <SparklesIcon class="ml-1 mr-4 h-6 w-6" />
            {{ $t('nav.nexus') }}
          </a>
          <a
            :href="posLink"
            class="flex rounded-lg px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-100 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
          >
            <BuildingStorefrontIcon class="ml-1 mr-4 h-6 w-6" />
            {{ $t('nav.posAddress') }}
          </a>
          <hr v-if="stripeConnected && arternalPayEnabled" class="my-2">
          <a
            v-if ="stripeConnected && arternalPayEnabled"
            :href="stripeLink"
            target="_blank"
            class="flex rounded-lg px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-100 hover:text-blue-800 focus:bg-blue-200 focus:outline-none"
          >
            <CreditCardIcon class="ml-1 mr-4 h-6 w-6" />
            {{ $t('nav.paymentDash') }}
          </a>
        </nav>

        <!-- <nav class="flex flex-col">
          <div class="px-5 py-3 uppercase tracking-widest text-zinc-800">
            {{ $t('nav.settings.heading') }}
          </div>

          <router-link
            :to="{ name: 'invoices-settings' }"
            class="flex px-5 py-3 text-zinc-800 !no-underline hover:bg-blue-200 focus:bg-blue-200 focus:outline-none"
            exact-active-class="!bg-slate-600 text-white"
            @click="toggleMenu(false)"
          >
            <CogIcon class="ml-1 mr-4 h-6 w-6" />

            {{ $t('nav.settings.invoice') }}
          </router-link>
        </nav> -->

        <nav
          v-if="!isUniversalNavbarEnabled"
          class="sticky bottom-0 mt-auto flex flex-col border-t bg-zinc-100 hover:bg-blue-100 hover:text-blue-800 focus:bg-blue-200"
        >
          <router-link
            :to="{ name: isAuthenticated ? 'logout' : 'login' }"
            class="flex items-center p-3 text-zinc-800 !no-underline hover:text-blue-800 focus:outline-none"
            @click="toggleMenu(false)"
          >
            <div class="mr-4 flex-shrink-0">
              <img
                v-if="account?.imageUrl || idTokenClaims?.picture"
                :src="account?.imageUrl ?? idTokenClaims?.picture"
                class="h-10 w-10 rounded-full"
              />
              <UserCircleIcon v-else class="ml-1 h-6 w-6" />
            </div>

            <div>
              <div class="capitalize leading-tight">
                {{ isAuthenticated ? fullName : $t('nav.signin') }}
              </div>

              <div
                v-if="isAuthenticated && idTokenClaims?.email"
                class="text-sm"
              >
                {{ idTokenClaims?.email }}
              </div>
            </div>
          </router-link>
        </nav>
      </div>
    </aside>

    <main
      class="container mx-auto px-5 pb-10 pt-10"
      :class="{ 'pt-24 lg:ml-[20rem] lg:px-10 lg:pt-10': chromeVisible }"
    >
      <Suspense>
        <router-view v-slot="{ Component, route: pageRoute }">
          <component :is="Component" :key="pageRoute.path" />
        </router-view>
      </Suspense>
    </main>

    <footer></footer>
  </div>
</template>
