import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { Account, Organization } from '@@/types'
import { OrganizationFragment } from '.'

export function empty(override: Partial<Organization> = {}): Organization {
  return {
    id: '',
    loginName: '',
    groupId: 0,
    ...override,
  }
}

const useGet =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (id: string): Promise<Organization> => {
    const { data } = await client.query({
      query: gql`
        query GetOrganization($id: Int!) {
          organization_by_pk(id: $id) {
            ...OrganizationFragment
          }
        }

        ${OrganizationFragment}
      `,
      variables: {
        id,
      },
    })

    return camelcaseKeys(data.organization_by_pk, { deep: true })
  }

const useGetUsers =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<Account[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const { data } = await client.query({
      query: gql`
        query GetOrgUsers {
          core {
            users {
              id
              first_name
              last_name
              email
            }
          }
        }
      `,
    })

    return camelcaseKeys(data.core.users)
  }

const useSearch =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<Organization[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const { data } = await client.query({
      query: gql`
        query getOrganizations {
          organization(where: { deleted: { _eq: false } }) {
            ...OrganizationFragment
          }
        }

        ${OrganizationFragment}
      `,
    })

    const organizations = data.organization.map(
      ({
        __typename,
        ...organization
      }: Organization & { __typename: string }) => ({
        ...organization,
        id: organization.id?.toString(),
      })
    )

    return camelcaseKeys(organizations, { deep: true })
  }

export const useOrganizationModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    get: useGet(client),
    search: useSearch(client),
    getUsers: useGetUsers(client),
  }
}
