<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { useAuth0 } from '@auth0/auth0-vue'
import { useMixpanel } from '@/plugins/mixpanel'

const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()
const route = useRoute()
const store = useStore()
const mixpanel = useMixpanel()

const onLogin = () => {
  return loginWithRedirect()
}

const auth0TokenExpired = computed(() => store.auth0TokenExpired)
const showModal = computed(() => {
  const shouldAllow = route.path && !route.path.includes('callback')
  return shouldAllow && isAuthenticated && auth0TokenExpired.value
})

window.addEventListener('visibilitychange', async () => {
  if (document?.visibilityState === 'visible') {
    // just validate session
    try {
      await getAccessTokenSilently()
      store.auth0TokenExpired = false
      // throw new Error('test')
    } catch (e: any) {
      console.log(`error`, e?.message)
      store.auth0TokenExpired = true
      mixpanel.reset()
    }
  }
})
</script>

<template>
  <div v-if="showModal">
    <div class="relative select-none">
      <div class="absolute z-[50001] top-0 left-0 w-full h-screen bg-white/80">
        <div class="bg-white text-black w-[350px] mx-auto mt-[25vh]">
          <div
            class="rounded-md overflow-hidden"
            style="border: 1px solid #ced3dc; box-shadow: 0 4px 8px 0 #eee"
          >
            <h5 class="bg-gray-500 p-3 text-white text-lg font-medium my-0">
              Please sign in again
            </h5>
            <p class="px-3 py-2 text-gray-600 text-sm">
              You were signed out of your account. Please press 'Reload' to sign in to
              Arternal again.
            </p>
            <div class="p-3 block bg-gray-200 text-right">
              <button
                type="button"
                class="px-5 py-2 font-medium rounded-md text-sm bg-gray-900 hover:bg-gray-800 text-white cursor-pointer border ml-auto"
                @click="onLogin"
              >
                Reload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
