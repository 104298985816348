import { Account, UserSetting } from '@@/types'

export default (account: Account, setting: keyof UserSetting) =>
  account.jsonSettings?.[0]?.setting?.[setting]?.default ||
  account.groups
    ?.map(
      (group) =>
        group.group?.organization?.jsonSettings?.[0].setting?.[setting]?.default
    )
    .filter(Boolean)[0] ||
  ''
