<script setup lang="ts">
import { ref, watch } from 'vue'
import { clamp, decimalSeparator } from '@/utils'

const props = withDefaults(
  defineProps<{
    min?: number
    max?: number
    locale?: string
    modelValue?: number | string
  }>(),
  {
    min: Number.NEGATIVE_INFINITY,
    max: Number.POSITIVE_INFINITY,
    locale: 'en-US',
    modelValue: 0,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const innerValue = ref('0')

const clampValue = (value: string) => {
  const decimalIndex = value.lastIndexOf(decimalSeparator(props.locale))
  const decimalCount =
    decimalIndex > -1
      ? Math.max(value.length - Math.max(decimalIndex, 0) - 1, 0)
      : 0

  const clamped = clamp(
    Number(Number(value).toFixed(Math.min(decimalCount, 2))),
    props.min,
    props.max
  )

  // HACK: force value to update, or field won't clamp while typing at max value
  innerValue.value = ''
  innerValue.value = clamped.toFixed(Math.min(decimalCount, 2))

  return clamped
}

const update = (value: string) => {
  const clamped = clampValue(value)
  emit('update:modelValue', clamped)
}

watch(
  () => [props.modelValue, props.min, props.max],
  ([value]) => clampValue(value.toString()),
  { immediate: true }
)
</script>

<template>
  <input
    type="number"
    :min="min"
    :max="max"
    :value="innerValue"
    @input="update(($event.target as HTMLFormElement).value)"
    @wheel="($event.target as HTMLFormElement).blur()"
  />
</template>
