import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import {
  Contact,
  ContactItem,
  ContactItemExtraAddress,
  ContactItemRaw,
} from '@@/types'
import { emptyAddress, emptyContact, CrmContactFragment } from '.'

export function empty(override: Partial<Contact> = {}): Contact {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phoneHome: '',
    phoneOffice: '',
    phoneMobile: '',
    phoneOther: '',
    ...override,
  }
}

const RelatedContactFragment = gql`
  fragment RelatedContactFragment on contact {
    contact_x_contacts {
      contact_linked_to {
        id
        first_name
        last_name
        email
        phone_home
        image_url
      }
    }
    contact_x_contacts_linked {
      contact_linked_from {
        id
        first_name
        last_name
        email
        phone_home
        image_url
      }
    }
  }
`

const useSearch =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    term,
    orgIds = [],
    limit = 20,
    addressSensitive = true,
  }: {
    term: string
    orgIds?: number[]
    limit?: number
    addressSensitive?: boolean
  }): Promise<ContactItem[]> => {
    const tokens =
      term
        .toLowerCase()
        .replace(/[^\w\s@]+/g, '')
        .match(/[^\s]+/g) ?? []
    const search = tokens.map((token) => `${token}:*`).join(' & ')

    const { data } = await client.query({
      query: gql`
        query searchContacts($search: tsquery, $limit: Int!, $orgIds: [Int!]) {
          search_contacts(
            limit: $limit
            args: { search: $search }
            where: {
              inactive: { _eq: false }
              archived: { _eq: false }
              deleted: { _eq: false }
              organization_id: { _in: $orgIds }
            }
            order_by: [{ name: asc }, { email: asc }]
          ) {
            ...CrmContactFragment
          }
        }

        ${CrmContactFragment}
      `,
      variables: {
        search,
        limit,
        orgIds,
      },
    })

    return camelcaseKeys(data.search_contacts).flatMap(
      (contact: ContactItemRaw) => {
        const contactDetails = emptyContact({
          attn: contact.company,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phoneHome: contact.phoneHome,
          phoneMobile: contact.phoneMobile,
          phoneOffice: contact.phoneOffice,
          phoneOther: contact.phoneOther,
          imageUrl: contact.imageUrl,
          honorific: contact.honorific,
          kycVerified: contact.kycVerified,
        })

        const contactWithPrimaryAddress = {
          id: contact.id.toString(),
          contact: contactDetails,
          address: emptyAddress({
            line1: contact.primaryStreet,
            line2: contact.primaryAddress2,
            locality: contact.primaryCity,
            region: contact.primaryState,
            country: contact.primaryCountry,
            postalCode: contact.primaryZip,
          }),
        }

        let contactWithSecondaryAddresses: ContactItem[] = []

        // If true, then a contact with multiple addresses will be considered multiple contacts
        if (addressSensitive) {
          contactWithSecondaryAddresses = Object.values(
            contact.extra.address || []
          ).map((address: ContactItemExtraAddress) => ({
            id: contact.id.toString(),
            contact: contactDetails,
            address: emptyAddress({
              line1: address.street,
              line2: address['street 2'],
              locality: address.city,
              region: address.state,
              country: address.country,
              postalCode: address.zip,
            }),
          }))
        }

        return [contactWithPrimaryAddress, ...contactWithSecondaryAddresses]
      }
    )
  }

const useGetRelated =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (contactId: number, orgIds: number[] = []): Promise<Contact[]> => {
    const { data } = await client.query({
      query: gql`
        query GetRelatedContacts($contactId: Int, $orgIds: [Int!]) {
          contact(
            where: {
              id: { _eq: $contactId }
              organization_id: { _in: $orgIds }
            }
          ) {
            ...RelatedContactFragment
          }
        }

        ${RelatedContactFragment}
      `,
      variables: {
        contactId,
        orgIds,
      },
    })

    // get contact and reverse relationships
    const related_data = data.contact[0].contact_x_contacts
    const related_linked_data = data.contact[0].contact_x_contacts_linked

    const related = related_data.length
      ? related_data.map((entry: Record<string, Contact>) =>
          camelcaseKeys(entry.contact_linked_to)
        )
      : null
    const related_linked = related_linked_data.length
      ? related_linked_data.map((entry: Record<string, Contact>) =>
          camelcaseKeys(entry.contact_linked_from)
        )
      : null

    if (related && related_linked) {
      return related.concat(related_linked)
    }
    return related || related_linked || []
  }

export const useContactModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    search: useSearch(client),
    getRelated: useGetRelated(client),
  }
}
