import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { OrganizationCustomField } from '@@/types'

const OrganizationCustomFieldFragment = gql`
  fragment organizationCustomFieldFragment on organization_custom_fields {
    id
    position
    attributes
    created_at
    updated_at
    archived_at
    deleted_at
  }
`

const useList =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<OrganizationCustomField[]> => {
    const { data } = camelcaseKeys(
      await client.query({
        query: gql`
          query listCustomFields {
            organization_custom_fields {
              ...organizationCustomFieldFragment
            }
          }

          ${OrganizationCustomFieldFragment}
        `,
      }),
      { deep: true }
    )

    return data.organizationCustomFields
  }

export const useCustomFieldModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    list: useList(client),
  }
}
