import { ref } from 'vue'
import { defineStore } from 'pinia'
import { Account, FeatureFlag } from '@@/types'

export const useStore = defineStore('main', () => {
    const account = ref<Account | null>(null)
    const auth0TokenExpired = ref(false)
    const loggedIn = ref(false)
    const featureFlags = ref<FeatureFlag[]>([])

    return {
      account,
      auth0TokenExpired,
      loggedIn,
      featureFlags,
    }
})
