<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { useMixpanel } from '@/plugins/mixpanel'
import { Cropper } from 'vue-advanced-cropper'
import { Alert, ConfirmDialog } from '@/components'
import 'vue-advanced-cropper/dist/style.css';

const props = defineProps<{
  show: boolean
  imgSrc: string
}>()

const emit = defineEmits<{
  (e: 'crop', blob: Blob): void
  (e: 'close'): void
}>()

const mixpanel = useMixpanel()
const toast = useToast()
const { t } = useI18n() 

const cropper = ref()
const crop = async () => {
  if (!cropper.value) {
    return
  }

  const blobCallback = (blob: Blob | null) => {
    if (blob) {
      emit('crop', blob)
    } else {
      toast.error(t('messages.imageCropFailed'))
    }
    emit('close')
  }

  const fileExtIndex = props.imgSrc.lastIndexOf('.')
  const fileType = fileExtIndex > -1 ? `image/${props.imgSrc.slice(fileExtIndex + 1)}` : 'image/png'

  cropper.value.getCanvas().toBlob(blobCallback, fileType)
  mixpanel.track('Cropped an image')
}

</script>

<template>
  <ConfirmDialog :show="show" :title="$t('components.imgCropper.title')" :confirm-text="$t('actions.crop')" no-auto-close title-class="font-base" size="lg" @confirm="crop">
    <template #confirm-text>
      <div class="flex items-center">
        {{ $t('actions.crop') }}
      </div>
    </template>

    <div class="pt-5">
      <Alert variant="info" class="mb-5">
        {{  $t('components.imgCropper.helpText') }}
      </Alert>

      <Cropper
        ref="cropper"
        class="max-h-96"
        image-class="img-bg-fallback"
        background-class="cropper-fbg"
        foreground-class="cropper-fbg"
        :stencil-props="{
          handlersClasses: { default: 'handlers-color' },
          linesClasses: { default: 'lines-color' }
        }"
        :src="imgSrc"
      />
    </div>
  </ConfirmDialog>
</template>

<style scoped>
:deep(.cropper-fbg) {
  background: #f5f5f5; /* bg-secondary-100 */
}

:deep(.handlers-color) {
  background: #0ea5e9; /* bg-primary-500 */
}

:deep(.lines-color) {
  background: #737373; /* bg-secondary-500 */
}

:deep(.img-bg-fallback) {
  background: white;
}
</style>
