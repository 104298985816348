export default async (url: string, cachebust = true): Promise<string> => {
  const urlObj = new URL(url)

  // add a short random string to the end of the url to prevent caching
  if (cachebust) {
    urlObj.searchParams.set('cachebust', Math.random().toString(36))
  }

  const res = await fetch(urlObj.toString())
  const blob = await res.blob()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result?.toString() ?? '')
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}
