import { createRouter, createWebHistory } from 'vue-router'
// import { authGuard } from '@auth0/auth0-vue'
import { getMixpanel } from './plugins/mixpanel'

// do not lazy load routes where chromeVisible is false
// this will keep the route loading synchronously and avoid a flash of chrome
// import PassLayout from '@/pages/PassLayout.vue'
// import Login from '@/pages/Login.vue'
// import Logout from '@/pages/Logout.vue'
// import Callback from '@/pages/Callback.vue'
import NotFound from '@/pages/404.vue'
// const Terms = () => import('@/pages/Terms.vue')
// const Populate = () => import('@/pages/Populate.vue')

// import TemplatesLayout from '@/pages/templates/Layout.vue'
// const TemplatesIndex = () => import('@/pages/templates/Index.vue')
// const TemplatesEdit = () => import('@/pages/templates/Edit.vue')
// const TemplatesDetails = () => import('@/pages/templates/Details.vue')

// import InvoiceLayout from '@/pages/invoices/Layout.vue'
// const InvoicesIndex = () => import('@/pages/invoices/Index.vue')
// const InvoicesCreate = () => import('@/pages/invoices/Create.vue')
// const InvoicesEdit = () => import('@/pages/invoices/Edit.vue')
// const InvoicesPayments = () => import('@/pages/invoices/Payments.vue')
// const InvoicesView = () => import('@/pages/invoices/View.vue')
// const InvoicesSend = () => import('@/pages/invoices/Send.vue')
// const InvoicesNotes = () => import('@/pages/invoices/Notes.vue')
import InvoicesReview from '@/pages/invoices/Review.vue'
import InvoicesVerify from '@/pages/invoices/Verify.vue'
// const InvoicesSentHistory = () => import('@/pages/invoices/SentHistory.vue')

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(_to, _from, savedPosition) {
    // scroll to position on back/forward buttons, otherwise scroll to top
    return savedPosition ?? { left: 0, top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'notfound' },
    },

    // {
    //   path: '/login',
    //   meta: { chromeVisible: false },
    //   component: Login,
    //   name: 'login',
    // },
    // {
    //   path: '/logout',
    //   component: Logout,
    //   name: 'logout',
    // },

    // {
    //   path: '/callback',
    //   component: Callback,
    //   name: 'callback',
    // },

    // {
    //   path: '/terms',
    //   component: Terms,
    //   name: 'terms',
    // },

    // {
    //   path: '/populate',
    //   component: Populate,
    //   name: 'populate',
    // },

    {
      path: '/404',
      component: NotFound,
      name: 'notfound',
      meta: { chromeVisible: false },
    },

    // {
    //   path: '/account',
    //   redirect: { name: 'logout' },
    // },

    // {
    //   path: '/templates',
    //   component: PassLayout,
    //   beforeEnter: authGuard,
    //   children: [
    //     {
    //       path: '',
    //       component: TemplatesIndex,
    //       name: 'templates',
    //     },
    //     {
    //       path: ':id',
    //       component: TemplatesLayout,
    //       props: true,
    //       children: [
    //         {
    //           path: '',
    //           component: TemplatesEdit,
    //           name: 'templates-edit',
    //           props: true,
    //         },
    //         {
    //           path: 'details',
    //           component: TemplatesDetails,
    //           name: 'templates-details',
    //           props: true,
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   path: '/invoices',
    //   component: PassLayout,
    //   beforeEnter: authGuard,
    //   children: [
    //     {
    //       path: '',
    //       component: InvoicesIndex,
    //       name: 'invoices',
    //     },
    //     {
    //       path: 'create/:templateId?',
    //       component: InvoicesCreate,
    //       name: 'invoices-create',
    //       props: true,
    //     },
    //     {
    //       path: ':id',
    //       component: InvoiceLayout,
    //       props: true,
    //       children: [
    //         {
    //           path: '',
    //           component: InvoicesEdit,
    //           name: 'invoices-edit',
    //           props: true,
    //         },
    //         {
    //           path: 'payments',
    //           component: InvoicesPayments,
    //           name: 'invoices-payments',
    //           props: true,
    //         },
    //         {
    //           path: 'view',
    //           component: InvoicesView,
    //           name: 'invoices-view',
    //           props: true,
    //         },
    //         {
    //           path: 'send',
    //           component: InvoicesSend,
    //           name: 'invoices-send',
    //           props: true,
    //         },
    //         {
    //           path: 'offer-history',
    //           component: InvoicesSentHistory,
    //           name: 'invoices-sent-history',
    //           props: true,
    //         },
    //         {
    //           path: 'notes',
    //           component: InvoicesNotes,
    //           name: 'invoices-notes',
    //           props: true,
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      path: '/review/:shareId/:templateId?',
      component: InvoicesReview,
      name: 'review',
      props: true,
      meta: { chromeVisible: false },
    },

    {
      path: '/review/:shareId/:templateId?/verify',
      component: InvoicesVerify,
      name: 'review-verify',
      props: true,
      meta: { chromeVisible: false },
    },
  ],
})

const mixpanel = getMixpanel()

router.beforeEach((to) => {
  const friendlyPageName = to.name?.toString().replace(/-/g, ' ')
  mixpanel.track(`Visit ${friendlyPageName}`, {
    page: to.name,
  })
})

// Force page reload if dynamic module could not be imported
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    window.location.href = to.fullPath
  }
})

export default router
