import { Plugin } from 'vue'

// sniffs the platform and styles the scrollbars if on Windows

export function createScrollbars(): Plugin {
  return {
    install: () => {
      if (navigator.userAgent.includes('Windows')) {
        document.documentElement.classList.add('style-scrollbars')
      }
    },
  }
}
