import isPlainObject from './isPlainObject'

// (v ?? null) normalizes nullish values (undefined, null) to null for easy comparison
const deepOmitNull = (input: unknown): unknown => {
  if (Array.isArray(input)) {
    return input.filter((v) => (v ?? null) !== null).map((v) => deepOmitNull(v))
  } else if (isPlainObject(input)) {
    return Object.fromEntries(
      Object.entries(input as Record<string, unknown>)
        .filter(([, v]) => (v ?? null) !== null)
        .map(([k, v]) => [k, deepOmitNull(v)])
    )
  } else {
    return input
  }
}

export default deepOmitNull
