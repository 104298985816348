import gql from 'graphql-tag'

export const AdditionalDetailFragment = gql`
  fragment AdditionalDetailFragment on DetailPreset {
    id
    name
    content
    type
    auto_include
    __typename
  }
`
