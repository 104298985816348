const fontUrl =
  'https://test-arternal.s3.us-east-2.amazonaws.com/styles/stylesheet.css'
const googleFontUrl =
  'https://fonts.googleapis.com/css?family=Inter:wght@200;400;600&display=swap'
const arimo =
  'https://fonts.googleapis.com/css2?family=Arimo:wght@200;400;600&display=swap'
const notoSansSC =
  'https://fonts.googleapis.com/css?family=Noto+Sans+SC&amp;subset=chinese-simplified'

export default () => {
  return [
    { fontFamily: 'Adobe Garamond', fontUrl },
    { fontFamily: 'Alte Haas Grotesk', fontUrl },
    { fontFamily: 'Aktiv Grotesk', fontUrl },
    { fontFamily: 'Akzidenz Grotesk', fontUrl },
    { fontFamily: 'Aptos', fontUrl },
    { fontFamily: 'Arial', fontUrl },
    { fontFamily: 'AvenirLTStd Book', fontUrl },
    { fontFamily: 'AvenirLTStd Medium', fontUrl },
    { fontFamily: 'AvenirLTStd Roman', fontUrl },
    { fontFamily: 'Arimo', fontUrl: arimo },
    { fontFamily: 'Big Carlson Medium', fontUrl },
    { fontFamily: 'Brandon Grotesque', fontUrl },
    { fontFamily: 'BrownStd', fontUrl },
    { fontFamily: 'BrownStd Alt', fontUrl },
    { fontFamily: 'Calibri', fontUrl },
    { fontFamily: 'Celaraz', fontUrl },
    { fontFamily: 'CentSchbook', fontUrl },
    { fontFamily: 'Cheltenham', fontUrl },
    { fontFamily: 'Comfortaa Light', fontUrl },
    { fontFamily: 'Comfortaa', fontUrl },
    { fontFamily: 'Courier New', fontUrl },
    { fontFamily: 'Dante', fontUrl },
    { fontFamily: 'DM Sans', fontUrl },
    { fontFamily: 'Dolly Roman', fontUrl },
    { fontFamily: 'Dolly Smallcaps', fontUrl },
    { fontFamily: 'Domaine Display Narrow', fontUrl },
    { fontFamily: 'EB Garamond', fontUrl },
    { fontFamily: 'EB Garamond Extra Bold', fontUrl },
    { fontFamily: 'EB Garamond Medium', fontUrl },
    { fontFamily: 'FF Sero Pro', fontUrl },
    { fontFamily: 'Founders Grotesk', fontUrl },
    { fontFamily: 'Galaxie Polaris Book', fontUrl },
    { fontFamily: 'Gill sans', fontUrl },
    { fontFamily: 'Gotham Book', fontUrl },
    { fontFamily: 'Helvetica Neue', fontUrl },
    { fontFamily: 'Inter', fontUrl: googleFontUrl },
    { fontFamily: 'IBM Plex Mono Light', fontUrl },
    { fontFamily: 'IBM Plex Mono', fontUrl },
    { fontFamily: 'IBM Plex Mono Medium', fontUrl },
    { fontFamily: 'Josefin Sans Light', fontUrl },
    { fontFamily: 'Josefin Sans Medium', fontUrl },
    { fontFamily: 'Lato', fontUrl },
    { fontFamily: 'Lato Light', fontUrl },
    { fontFamily: 'Lucida Sans', fontUrl },
    { fontFamily: 'Lusitana', fontUrl },
    { fontFamily: 'Neuzeit Grotesk', fontUrl },
    { fontFamily: 'Noto Sans SC', fontUrl: notoSansSC },
    { fontFamily: 'Penumbra Flare', fontUrl },
    { fontFamily: 'Penumbra Sans Light', fontUrl },
    { fontFamily: 'Penumbra Sans', fontUrl },
    { fontFamily: 'Proxima Nova', fontUrl },
    { fontFamily: 'Questrial', fontUrl },
    { fontFamily: 'Quincy CF', fontUrl },
    { fontFamily: 'Spectral', fontUrl },
    { fontFamily: 'Standard', fontUrl },
    { fontFamily: 'Sofia Pro Light', fontUrl },
    { fontFamily: 'Relative Bold', fontUrl },
    { fontFamily: 'Relative Faux', fontUrl },
    { fontFamily: 'Relative Medium', fontUrl },
    { fontFamily: 'Relative Book', fontUrl },
    { fontFamily: 'Relative Mono 10', fontUrl },
    { fontFamily: 'Relative Mono 11', fontUrl },
    { fontFamily: 'Relative Mono 12', fontUrl },
    { fontFamily: 'Telluride Regular', fontUrl },
    { fontFamily: 'Telluride Regular Black', fontUrl },
    { fontFamily: 'Telluride Regular Extra Bold', fontUrl },
    { fontFamily: 'Telluride Regular Light', fontUrl },
    { fontFamily: 'Telluride Regular Medium', fontUrl },
    { fontFamily: 'Telluride Regular Semi Bold', fontUrl },
    { fontFamily: 'Times New Roman', fontUrl },
    { fontFamily: 'Union', fontUrl },
    { fontFamily: 'Univers', fontUrl },
    { fontFamily: 'Untitled Sans Light', fontUrl },
    { fontFamily: 'Urbanist', fontUrl },
    { fontFamily: 'Verdana', fontUrl },
    { fontFamily: 'Verlag', fontUrl },
    { fontFamily: 'Verlag Black', fontUrl },
    { fontFamily: 'Verlag Light', fontUrl },
    { fontFamily: 'Victor Serif', fontUrl },
  ]
}
