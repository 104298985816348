export default (
  cents: number,
  {
    precision = 2,
    fixed = true,
    currency = 'USD',
    locale = 'en-US',
    showCurrencySymbol = true,
  }: {
    precision?: number
    fixed?: boolean
    currency?: string
    locale?: string
    showCurrencySymbol?: boolean
  } = {}
) => {
  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fixed ? precision : 0,
    maximumFractionDigits: precision,
  }).format(cents / 100)

  // eslint-disable-next-line no-useless-escape
  return showCurrencySymbol ? formatted : formatted.match(/[\d,\.]+/)?.[0] ?? ''
}
