<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useAttrs } from 'vue'
import {
  AutoGrowTextarea,
  Btn,
  DropdownMenu,
  MenuItemButton,
} from '@/components'
import { VueClassAttr } from '@@/types'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholders: string[]
    prefix?: string
    suffix?: string
    case?: 'uppercase' | 'lowercase'
    inputClass?: VueClassAttr
  }>(),
  {
    modelValue: '',
    prefix: '[',
    suffix: ']',
    case: undefined,
    inputClass: undefined,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const root = ref<HTMLElement>()
const element = ref<HTMLTextAreaElement>()

onMounted(() => {
  if (!root.value) return

  const el = root.value.querySelector('textarea')
  if (!el) return console.error('InjectPlaceholder missing child textarea')

  element.value = el
})

const inject = async (placeholder: string) => {
  if (!element.value) return

  const { selectionStart, selectionEnd } = element.value

  const cased = props.case
    ? props.case === 'uppercase'
      ? placeholder.toUpperCase()
      : placeholder.toLowerCase()
    : placeholder
  const wrapped = props.prefix + cased + props.suffix

  const text =
    props.modelValue?.slice(0, selectionStart) +
    wrapped +
    props.modelValue?.slice(selectionEnd)
  emit('update:modelValue', text)

  element.value.focus()
  await nextTick()

  // move cursor to end of insert
  const newEnd = selectionStart + wrapped.length
  element.value.setSelectionRange(newEnd, newEnd)
}

const isLowerCased = () => {
  return props.case === 'lowercase'
}

const wrap = async (prefix: string, suffix: string) => {
  if (!element.value) return

  const { selectionStart, selectionEnd } = element.value

  const text =
    props.modelValue?.slice(0, selectionStart) +
    prefix +
    props.modelValue?.slice(selectionStart, selectionEnd) +
    suffix +
    props.modelValue?.slice(selectionEnd)
  emit('update:modelValue', text)

  element.value.focus()
  await nextTick()

  const newEnd = selectionEnd + prefix.length
  const newStart = selectionStart + prefix.length
  element.value.setSelectionRange(newStart, newEnd)
}

// we want all attributes to be bound to the textarea but classes on root node
const attrs = useAttrs()
const attrsNoClass = computed(() => {
  const { class: _class, ...rest } = attrs
  return rest
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<template>
  <div :class="$attrs.class">
    <header class="mb-1 flex items-center gap-2 rounded bg-gray-100 p-1">
      <DropdownMenu v-if="placeholders.length" button-class="" button-size="sm">
        <template #icon>
          <div class="flex items-center gap-2">
            {{ $t('actions.placeholders') }}
            <ChevronDownIcon class="h-5 w-5" />
          </div>
        </template>

        <template #default>
          <MenuItemButton
            v-for="placeholder in placeholders"
            :key="placeholder"
            @click="inject(placeholder)"
          >
            {{ isLowerCased() ? placeholder.toLowerCase() : placeholder }}
          </MenuItemButton>
        </template>
      </DropdownMenu>

      <Btn
        size="sm"
        outline
        class="w-8 !border-transparent font-bold"
        @click="wrap('*', '*')"
        >B</Btn
      >
      <Btn
        size="sm"
        outline
        class="w-8 !border-transparent font-serif italic"
        @click="wrap('_', '_')"
        >i</Btn
      >
      <Btn
        size="sm"
        outline
        class="w-8 !border-transparent font-serif underline"
        @click="wrap('<u>', '</u>')"
        >U</Btn
      >
    </header>

    <div ref="root">
      <slot>
        <AutoGrowTextarea
          v-bind="attrsNoClass"
          :class="inputClass"
          :model-value="modelValue"
          @update:model-value="$emit('update:modelValue', $event)"
        />
      </slot>
    </div>
  </div>
</template>
