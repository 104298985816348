import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { v4 as uuid } from 'uuid'
import { Inventory, InventoryXContact, LineItem } from '@@/types'
import {
  ArtistFragment,
  CrmContactFragment,
  InventoryFragment,
  InventoryMiscFragment,
  InventoryXContactFragment,
} from '.'

export function empty(override: Partial<LineItem> = {}): LineItem {
  return {
    idPlaceholder: uuid(),
    description: '',
    price: 0,
    currency: 'USD',
    discountAmount: 0,
    discountedPrice: 0,
    taxAmount: 0,
    imageUrl: null,
    meta: {},
    specialCase: '',
    inventory: null,
    dirty: true,
    ...override,
  }
}

const useSearch =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (
    term: string,
    orgIds: number[] = [],
    limit = 20
  ): Promise<Inventory[]> => {
    const tokens =
      term
        .toLowerCase()
        .replace(/[^\w\s]+/g, '')
        .match(/[^\s]+/g) ?? []
    const search = tokens.map((token) => `${token}:*`).join(' & ')
    const { data } = await client.query({
      query: gql`
        query searchInventory($search: tsquery, $limit: Int!, $orgIds: [Int!]) {
          search_inventory(
            limit: $limit
            args: { search: $search }
            where: {
              archived: { _eq: false }
              deleted: { _eq: false }
              type: { _in: ["inventory", "edition"] }
              organization_id: { _in: $orgIds }
            }
          ) {
            ...InventoryFragment
          }
        }
        ${ArtistFragment}
        ${CrmContactFragment}
        ${InventoryMiscFragment}
        ${InventoryXContactFragment}
        ${InventoryFragment}
      `,
      variables: {
        search,
        limit,
        orgIds,
      },
    })

    return camelcaseKeys(data.search_inventory, {
      deep: true,
      exclude: ['__typename'],
    })
  }

const useMarkInventoryAsSold =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (ids: (string | number)[]) => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation MarkInventoryAsSold($ids: [Int!]) {
          update_inventory(
            where: { id: { _in: $ids } }
            _set: { status: "sold" }
          ) {
            returning {
              status
            }
          }
        }
      `,
      variables: {
        ids,
      },
    })

    return data.update_inventory
  }

const useGetInventory =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (
    ids: (string | number)[],
    orgIds: number[] = []
  ): Promise<Inventory[]> => {
    const { data } = await client.query({
      query: gql`
        query GetInventory($ids: [Int!], $orgIds: [Int!]) {
          inventory(
            where: { id: { _in: $ids }, organization_id: { _in: $orgIds } }
          ) {
            ...InventoryFragment
          }
        }
        ${ArtistFragment}
        ${CrmContactFragment}
        ${InventoryMiscFragment}
        ${InventoryXContactFragment}
        ${InventoryFragment}
      `,
      variables: {
        ids,
        orgIds,
      },
    })

    return camelcaseKeys(data.inventory, {
      deep: true,
      exclude: ['__typename'],
    })
  }

const useCreateContactAssociation =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async ({
    inventoryIds = [],
    contactIds,
    status,
    userId,
  }: {
    inventoryIds?: number[]
    contactIds: number[]
    status: string
    userId: number
  }): Promise<InventoryXContact[]> => {
    const { data } = await client.mutate({
      mutation: gql`
        mutation createContactAssociation(
          $inventoryId: Int!
          $contactIds: [Int]!
          $status: String
          $userId: Int
          $sendEmail: Boolean
          $multiInventory: [Int]
        ) {
          core {
            createInventoryContact(
              inventoryId: $inventoryId
              contactId: $contactIds
              status: $status
              userId: $userId
              sendEmail: $sendEmail
              ${
                inventoryIds.length > 1 ? 'multiInventory: $multiInventory' : ''
              }
            ) {
              id
            }
          }
        }
      `,
      variables: {
        inventoryId: inventoryIds[0],
        contactIds,
        status,
        userId,
        sendEmail: false,
        multiInventory: inventoryIds,
      },
    })

    return data.core.createInventoryContact?.id
  }

export const useLineItemModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    search: useSearch(client),
    markInventoryAsSold: useMarkInventoryAsSold(client),
    getInventory: useGetInventory(client),
    createContactAssociation: useCreateContactAssociation(client),
  }
}
