import gql from 'graphql-tag'
import { InventoryFragment } from '../inventory'

export const TransactionItemFragment = gql`
  fragment TransactionItemFragment on TransactionItem {
    id
    description
    price
    currency
    discount
    discount_percent
    image_url
    meta
    position
    is_taxed
    special_case
    inventory {
      ...InventoryFragment
    }
    __typename
  }
  ${InventoryFragment}
`