import { ApolloClient, InMemoryCache } from '@apollo/client/core'

// TODO: remove this file once taxrate model is convert to use arternal api

// this only points to the fake API
const client = new ApolloClient({
  uri: import.meta.env.VITE_API_URL,
  cache: new InMemoryCache(),
  // disable cache
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

export { client }
