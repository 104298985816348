import { createAuth0 } from '@auth0/auth0-vue'

export default createAuth0(
  {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    // redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    // TODO: remove, for testing auth0 across multiple subdomains
    cookieDomain:
      process.env.NODE_ENV !== 'development' ? '.arternal.com' : undefined,

    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience: 'https://api.arternal.com',
      scope: 'openid profile email offline_access',
    },
    // This is now required. This fixes the missing_refresh_token
    // Read more here: https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/15
    // We need to enabled cust domains and our own domain so the cookie is shared.
    // and then use useRefreshTokensFallback = true which uses an iframe to get a new token.
    useRefreshTokensFallback: true,
  },
  {
    // TODO: may not be needed if we don't need to set top-level domain cookie
    // Commented DEA-207
    // This causes issue with Safari site tracking. Refer ticket # above for notes.
    // skipRedirectCallback: true,
  }
)
