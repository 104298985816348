import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { Address } from '@@/types'
import { SaleAddressFragment } from '.'

export function empty(override: Partial<Address> = {}): Address {
  return {
    name: '',
    label: '',
    line1: '',
    line2: '',
    unit: '',
    streetNumber: '', // route on google
    streetName: '',
    locality: '', // city, town, etc.
    region: '', // province, state, etc.
    country: '',
    postalCode: '',
    isDefault: false,
    ...override,
  }
}

const useSearch =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (limit = 20): Promise<Address[]> => {
    const { data } = await client.query({
      query: gql`
        query getAddresses($limit: Int!) {
          organization_sale_addresses(
            where: { type: { _eq: "work" } }
            limit: $limit
          ) {
            ...SaleAddressFragment
          }
        }

        ${SaleAddressFragment}
      `,
      variables: {
        limit,
      },
    })

    return camelcaseKeys(
      data.organization_sale_addresses.map((addr: Address) => ({
        ...addr,
        id: `${addr.id}`, // force id to string
      }))
    )
  }

const useGetDefault =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (): Promise<Address | undefined> => {
    const { data } = await client.query({
      query: gql`
        query getDefaultAddress {
          organization_sale_addresses(
            where: { type: { _eq: "work" }, is_default: { _eq: true } }
            limit: 1
          ) {
            ...SaleAddressFragment
          }
        }

        ${SaleAddressFragment}
      `,
    })

    // May be undefined if no default address is set
    const addr = data.organization_sale_addresses.at(0)
    return addr
      ? camelcaseKeys({
          ...addr,
          id: `${addr.id}`, // force id to string
        })
      : empty()
  }

export const useAddressModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    search: useSearch(client),
    getDefault: useGetDefault(client),
  }
}
