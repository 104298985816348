<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const field = ref()

watch(
  () => props.modelValue,
  () => updateHeight()
)

const updateHeight = async () => {
  await nextTick()

  // reduce the size to 0 to get accurate scroll height
  field.value.style.height = 0
  // add 2 pixels to prevent scrollbars
  field.value.style.height = `${field.value.scrollHeight + 2}px`
}

const input = (e: Event) => {
  updateHeight()
  emit('update:modelValue', (e.target as HTMLTextAreaElement).value)
}

onMounted(() => {
  updateHeight()
})
</script>

<template>
  <textarea
    ref="field"
    data-lpignore="true"
    :value="modelValue"
    @input="input"
  />
</template>
