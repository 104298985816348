export interface SignedUrlPayload {
  signedUrl: string
  url: string
  expiresIn: number
  expiresAt: string
}

export default async (
  file: File,
  {
    key = '',
    signingUrl = import.meta.env.VITE_UPLOAD_SERVICE_URL,
    authorization = '',
  } = {}
) => {
  if (authorization.trim().length === 0) {
    console.warn('authorization header is blank')
  }

  // get a signed URL from our upload service
  const res = await fetch(signingUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
    body: JSON.stringify({
      key: key || file.name,
    }),
  })

  const data: SignedUrlPayload = await res.json()

  // upload file to signed URL
  await fetch(data.signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  })

  return data.url
}
