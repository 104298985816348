import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import camelcaseKeys from 'camelcase-keys'
import { Currency } from '@@/types'

const CurrencyFragment = gql`
  fragment CurrencyFragment on currency {
    code
    name
  }
`

export function empty(override: Partial<Currency> = {}): Currency {
  return {
    code: '',
    name: '',
    ...override,
  }
}

const useGetIn =
  (client: ApolloClient<NormalizedCacheObject>) =>
  async (codes: string[] = []): Promise<Currency[]> => {
    const { data } = await client.query({
      query: gql`
        query GetCurrencyIn($codes: [String]) {
          currency(where: { code: { _in: $codes } }) {
            ...CurrencyFragment
          }
        }

        ${CurrencyFragment}
      `,
      variables: {
        codes,
      },
    })

    return camelcaseKeys(data.currency, { deep: true })
  }

export const useCurrencyModel = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  return {
    empty,
    getIn: useGetIn(client),
  }
}
