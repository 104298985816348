import gql from 'graphql-tag'

export const SaleAddressFragment = gql`
  fragment SaleAddressFragment on OrganizationSaleAddress {
    id
    name
    label
    line_1
    line_2
    locality
    region
    country
    postal_code
    is_default
    __typename
  }
`