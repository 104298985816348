import { cleanJoin } from '@/utils'
import { Address } from '@@/types'

export default (address: Address, pairZipWithCountry?: boolean) => {
  const lines = []
  if (address.line1) {
    lines.push(address.line1)
  }
  if (address.line2) {
    lines.push(address.line2)
  }

  if (pairZipWithCountry === true && (address.locality || address.region)) {
    lines.push(cleanJoin([address.locality, address.region], ', '))
  } else if (address.locality || address.region || address.postalCode) {
    lines.push(
      cleanJoin(
        [address.locality, cleanJoin([address.region, address.postalCode])],
        ', '
      )
    )
  }

  if (pairZipWithCountry === true && (address.postalCode || address.country)) {
    lines.push(cleanJoin([address.country, address.postalCode], ' '))
  } else if (address.country) {
    lines.push(address.country)
  }
  return lines
}
