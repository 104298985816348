import slug from './simpleSlug'

export default (
  input: string,
  ext: string,
  {
    fallback = 'download',
    replacement = '-',
  }: {
    fallback?: string
    replacement?: string
  } = {}
) =>
  (slug(input, replacement) || slug(fallback, replacement)) +
  (ext ? `.${ext.toLowerCase()}` : '')
