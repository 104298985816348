import gql from 'graphql-tag'

export const WorkflowFragment = gql`
  fragment WorkflowFragment on Workflow {
    id
    name
    stages {
      id
      name
      meta
      order
    }
    transitions {
      id
      name
      from_workflow_stage_id
      to_workflow_stage_id
    }
    __typename
  }
`